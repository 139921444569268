import React, { useEffect, useState } from 'react'
import InfiniteScroll from "react-infinite-scroller";
import ListGroup from 'react-bootstrap/ListGroup';
import { Image, Card, Row, Col, Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import { AccountBalanceOutlined } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete';
// import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SvgIcon } from '@mui/material';
import { Home, Search } from "@mui/icons-material";
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BadgeAvatar from '../../../BadgeAvatar';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { Modal } from 'react-bootstrap';
import Upload from "../../../Cropper/Upload";
import Popup from "../../../Cropper/Popup";
import Box from "@mui/material/Box";
import { InputTags } from 'react-bootstrap-tagsinput'
import 'react-bootstrap-tagsinput/dist/index.css'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import libraryThumbnail from '../../../../assets/img/default-library-thumbnail.jpg';
import { styled } from "@mui/material/styles";
import axios from "axios";
import PVLNLibraryDetails from './PVLNLibraryDetails';
import CRUDDropdown from '../../../CRUDDropdown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import http from '../../../../http';
import pubThumbnail from '../../../../assets/img/default-pubn-thumbnail.jpg';
import CropperPopup from "../../../Cropper/Popup";
import PreLoader from '../../../PreLoader';
import { PLSIZE } from '../../../PavilionConstants';
import Swal from 'sweetalert2'
import { TagsInput } from "react-tag-input-component";
import Spinner from 'react-bootstrap/Spinner';
const CropperInput = styled("input")({
  display: "none"
});


function PVLNLibrary(props, { handleClick }) {
  const [progress, setProgress]=useState(false);
  const [imageLib, setImageLib] = useState('');
  const [permissions, setPermissions] = useState({});
  const [hasMore, setHasMore] = useState(true);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [loadMsg, setLoadMsg] = useState(<PreLoader size={PLSIZE} />);
  const token = localStorage.getItem('token');
  const [userdetails, setUserdetails] = useState([]);
  const [post, setPost] = useState([]);
  const [category, setCategory] = useState([]);
  const libraryImagePath = localStorage.getItem('bucketName') + '/images/';
  const libraryImagePathStatic = localStorage.getItem('bucketName') + '/images/noimage.webp';
  const [imageFile, setFile] = useState('');
  const [pdffile, setPdfFile] = useState('');
  const [docsourcetype, setDocsourcetype] = useState('weblink');
  const [pdffiledata, setPdfFileData] = useState('');
  const [libraryname, setLibraryname] = useState('');
  const [librarydescription, setLibraryDescription] = useState('');
  const [librarycategory, setLibraryCategory] = useState([]);
  const [librarydoctype, setLibraryDocType] = useState([]);
  const [librarytags, setLibraryTags] = useState([]);
  const [libraryweblink, setLibraryWebLink] = useState('');
  const [librarydate, setLibraryDate] = useState('');
  const [libraryidvalue, setLibraryidvalue] = useState('0');
  const [imagePress, setImagePress] = useState(pubThumbnail);
  const [imagePress2, setImagePress2] = useState('');
  const [imagePressedit, setImagePressEdit] = useState('');
  const [state, setState] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [selectedDocuValue, setSelectedDocValue] = useState([]);
  const [categoryhidden, setcategoryhidden] = useState([]);
  const [doctypehidden, setdoctypehidden] = useState([]);
  const [dsType, setDSType] = useState('weblink');
  const [category_id, setCategoryId] = useState();
  const [document_type, setDocumentType] = useState();
  const [year, setYear] = useState();
  const [filter, setFilter] = useState();
  const [libraryImagecheck, setlibraryImagecheck] = useState('');


  const handleLibraryCategoryChangeSearch = (e) => {
    setCategoryId(e.target.value);
  };
  const handleLibraryDocumentChange = (e) => {
    setDocumentType(e.target.value);
  };
  const handleLibraryYearChange = (e) => {
    setYear(e.target.value);
  };
  const handleLibraryTitleChange = (e) => {
    setFilter(e.target.value);
  };
  const handleClickLibrary = (e) => {
    setShowLibs(0);
    http().post('/searchlibrarydocument', {
      pavilion_id: props.idx,
      category_id: category_id,
      document_type: document_type,
      year: year,
      filter: filter,
      user_id: "",
    }).then((data) => {
      //console.log(data.documentCategory);
      //console.log(data.data.data.publication);
      setPost(data.data.data.libraryPavilionRecord.data);
      setCategory(data.data.data.libraryPavilionPublicationCategory);
    });
  };
  const handlePdfFileChange = (e) => {
    e.preventDefault();
    if (dsType == 'pdfFile') {
      setPdfFile('');
      let files;
      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files;
      }
      if (files.length === 0) {
        return alert("Please select a file.");
      }
      const reader = new FileReader();
      reader.onload = () => {
        setPdfFileData(reader.result);
      };
      reader.readAsDataURL(files[0]);
      setLibraryWebLink('pdfFile');
    }
  };
  const handleLibraryNameChange = (e) => {
    setLibraryname(e.target.value);
  };
  const handleLibraryDescriptionChange = (e) => {
    setLibraryDescription(e.target.value);
  };
  const handleLibraryCategoryChange = (e) => {
    setLibraryCategory(e.target.value);
    setcategoryhidden(e.target.value);

  };
  const handleLibraryDocTypeChange = (e) => {
    setLibraryDocType(e.target.value);
    setdoctypehidden(e.target.value);
  };
  const handleLibraryTagsChange = (e) => {
    setLibraryTags(e.target.value);
  };
  const handleLibraryWebLinkChange = (e) => {
    /*if(dsType=='pdfFile'){
      setPdfFile(e.target.value);
      setPdfFileData('');
      setLibraryWebLink('pdfFile');
    }*/
    if (dsType == 'weblink') {
      setPdfFile(e.target.value);
      setPdfFileData('');
      setLibraryWebLink('weblink');
    }
  };
  const handleLibraryDateChange = (e) => {
    setLibraryDate(e.target.value);
  };
  const [errors, setErrors] = useState({
    libraryname: '',
    librarydescription: '',
    librarycategory: '',
    librarydoctype: '',
    librarytags:'',
    librarydate: '',
  });
  const handleClickLibrarySubmit = (e) => {
    const newErrors = {};
    if (imagePress === '/static/media/default-pubn-thumbnail.ebb11ef1f10383bb3556.jpg') {
      newErrors.imagePress = 'Image is required.';
    }
    if (!libraryname) {
      newErrors.libraryname = 'Name is required.';
    }
    if (!librarydescription) {
      newErrors.librarydescription = 'Description is required.';
    }
    if (!librarycategory) {
      newErrors.librarycategory = 'Category is required.';
    }
    if (!librarydoctype) {
      newErrors.librarydoctype = 'Document type is required.';
    }
    if(!librarytags){
      newErrors.librarytags = 'Tags is required.';
    }
    if (!librarydate) {
      newErrors.librarydate = 'Date is required.';
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setProgress(true)
      if (document.getElementById('libraryidvalue').value != 0) {
        if (libraryImagecheck === imagePress) {
          http().post('/webcreatedocument', {
            pavilion_id: props.idx,
            library_state: "edit",
            library_id: document.getElementById('libraryidvalue').value,
            type_data: "",
            image: "",
            name: libraryname,
            description: librarydescription,
            category_id: librarycategory,
            document_type: librarydoctype,
            tags: librarytags,
            weblink_file: document.getElementById('doc-source-type').value,
            url: pdffile,
            file: pdffiledata,
            publication_date: librarydate,

          }).then((data) => {
			setPost([]);
            setHasMore(true);
            setLoadMsg('');
            setPage(1);
            http().get(`/library/${props.idx}?page=1`).then((response) => {
              setPost([...post, ...response.data.data.libraryPavilionRecord.data]);
              setPermissions(response.data.data.permission);
              const last_page=response.data.data.libraryPavilionRecord.last_page;
             if(page < last_page){
               setPage(page + 1);     
               setHasMore(true)
               setLoadMsg(<PreLoader size={PLSIZE} />)
             } else {
               setHasMore(false)
               setLoadMsg('No More Records...')
             }
           });
		   
            setImagePress(pubThumbnail);
            Swal.fire({
              title: "It is submitted successfully!",
              icon: "success",
              allowOutsideClick: false,
              confirmButtonColor: "#e30613",
            });
            //http().get('/library/' + props.idx).then((data) => {
            //  setPost(data.data.data.libraryPavilionRecord.data);
            //  setCategory(data.data.data.libraryPavilionPublicationCategory);
            //});
            setProgress(false)
            setShow(false);

          });
        } else {
          http().post('/webcreatedocument', {
            pavilion_id: props.idx,
            library_state: "edit",
            library_id: document.getElementById('libraryidvalue').value,
            type_data: "",
            image: imagePress,
            name: libraryname,
            description: librarydescription,
            category_id: librarycategory,
            document_type: librarydoctype,
            tags: librarytags,
            weblink_file: document.getElementById('doc-source-type').value,
            url: pdffile,
            file: pdffiledata,
            publication_date: librarydate,

          }).then((data) => {
			setPost([]);
            setHasMore(true);
            setLoadMsg('');
            setPage(1);
            http().get(`/library/${props.idx}?page=1`).then((response) => {
              setPost([...post, ...response.data.data.libraryPavilionRecord.data]);
              setPermissions(response.data.data.permission);
              const last_page=response.data.data.libraryPavilionRecord.last_page;
             if(page < last_page){
               setPage(page + 1);     
               setHasMore(true)
               setLoadMsg(<PreLoader size={PLSIZE} />)
             } else {
               setHasMore(false)
               setLoadMsg('No More Records...')
             }
           });
            setImagePress(pubThumbnail);
            Swal.fire({
              title: "It is submitted successfully!",
              icon: "success",
              allowOutsideClick: false,
              confirmButtonColor: "#e30613",
            });

            //http().get('/library/' + props.idx).then((data) => {
            //  setPost(data.data.data.libraryPavilionRecord.data);
            //  setCategory(data.data.data.libraryPavilionPublicationCategory);
            //});
            setProgress(false)
            setShow(false);
          });
        }
      } else {
        //setShowPubs(0);

        http().post('/webcreatedocument', {
          pavilion_id: props.idx,
          library_state: "create",
          type_data: "",
          image: imagePress,
          description: librarydescription,
          category_id: librarycategory,
          tags: librarytags,
          weblink_file: libraryweblink,
          url: pdffile,
          file: pdffiledata,
          publication_date: librarydate,
          name: libraryname,
          document_type: librarydoctype,

        }).then((data) => {
          setPost([]);
          setHasMore(true);
          setLoadMsg('');
          setPage(1);
          http().get(`/library/${props.idx}?page=1`).then((response) => {
            setPost([...post, ...response.data.data.libraryPavilionRecord.data]);
            setPermissions(response.data.data.permission);
            const last_page=response.data.data.libraryPavilionRecord.last_page;
           if(page < last_page){
             setPage(page + 1);     
             setHasMore(true)
             setLoadMsg(<PreLoader size={PLSIZE} />)
           } else {
             setHasMore(false)
             setLoadMsg('No More Records...')
           }
         });
          setImagePress(pubThumbnail);
          Swal.fire({
            title: "It is submitted successfully!",
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });
        //  http().get('/library/' + props.idx).then((data) => {
        //    setPost(data.data.data.libraryPavilionRecord.data);
        //    setCategory(data.data.data.libraryPavilionPublicationCategory);
        //  });
          setProgress(false)
          setShow(false);
        });
      }
    }
  };


  useEffect(() => {
    http().get('/libraryDocumentTypeAndCategory/' + props.idx).then((data) => {
      console.log('ssss');
      console.log(data.data.data.LibraryCategory);
      console.log(data.data.data.documentCategory);
      setCategoryData(data.data.data.LibraryCategory);
      setDocumentData(data.data.data.documentCategory);
    });
  }, []);



  const handleClickDelete = (id) => {
    Swal.fire({
      title: "Are you sure to delete this Library?",
      text: "You won't be able to revert this!",
      icon: "warning",
      width: 'auto',
      showCancelButton: true,
      confirmButtonColor: "#e30613",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        http().post('/delete_resource', {
          id: id,
          type: "librarydocument",
        }).then((data) => {
          Swal.fire({
            title: "Your Library has been deleted.",
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });
          setShow(false);
          http().get('/library/' + props.idx).then((data) => {
            setPost(data.data.data.libraryPavilionRecord.data);
            setCategory(data.data.data.libraryPavilionPublicationCategory);
          });

        });
      }
    });

  }


  //const  handleClickEdit = (variable) => {
  const handleClickEdit = (id) => {
    setShow(true)
    http().get('/editlibrary/' + id + '/' + props.idx).then((data) => {
      setLibraryname(data.data.data.libraryPavilionEditRecord.name);
      setLibraryDescription(data.data.data.libraryPavilionEditRecord.description);
      setLibraryTags(data.data.data.libraryPavilionEditRecord.tags.split('@##@'));
      setImagePress(data.data.data.libraryPavilionEditLibraryImage);
      setLibraryDate(data.data.data.libraryPavilionEditDateValue);
      setLibraryidvalue(data.data.data.libraryPavilionEditRecord.id);
      setLibraryCategory(data.data.data.libraryPavilionEditRecord.category_id);
      setLibraryDocType(data.data.data.libraryPavilionEditRecord.document_type);
      setcategoryhidden(data.data.data.libraryPavilionEditRecord.category_id);
      setdoctypehidden(data.data.data.libraryPavilionEditRecord.document_type);
      if (data.data.data.libraryPavilionEditRecord.url.substring(0, 3) == 'URL') {
        setPdfFileData('');
        setLibraryWebLink('weblink');
        setDSType('weblink');
        setDocsourcetype('weblink');
        setPdfFile(data.data.data.libraryPavilionEditRecord.url.substring(3));
      } else {
        setPdfFileData('');
        setLibraryWebLink('pdfFile');
        setDocsourcetype('pdfFile');
        setDSType('pdfFile');
      }
      setlibraryImagecheck(data.data.data.libraryPavilionEditLibraryImage);
    });
  }

  useEffect(() => {
    http().get('/userdetails').then((data) => {
      console.log('userdetails1');
      console.log(data);
      console.log('userdetails2');
      setUserdetails(data.data.data.user);
      console.log('userdetails3');
      //console.log(data.data.data.user.data);

    });
  }, []);


  const [showLibs, setShowLibs] = useState(0)
  const [open, setOpen] = React.useState(false);
  const [image, setImage] = React.useState(libraryThumbnail);
  const handleClose = () => {
    setOpen(false);
  };

  const [fileUrl, setFileUrl] = useState('https://source.unsplash.com/random/250x335/?sig=0');

  // const [open, setOpen] = useState(false);
  const [toggler, setToggler] = useState(false);
  const [show, setShow] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const handleParentClose = () => { setProgress(false); setShow(false); }
  const handleShow = () => {
    setShow(true);
    setLibraryname('');
    setLibraryDescription('');
    setLibraryTags([]);
    setImage(libraryThumbnail);
    setSelectedValue('');
    setSelectedDocValue('');
    setLibraryDate('');
    setLibraryidvalue('0');
    setLibraryCategory('');
    setLibraryDocType('');
    setDocsourcetype('weblink');
    setDSType('weblink');
  }


  const [cropData, setCropData] = useState("#");
  const handleBoxClick = () => {
    setShowPopup(true);
  }

  const handleDSTypeClick = (e) => {
    if (e.target.value === "weblink") {
      setDSType('weblink')
      setPdfFile('');
    } else {
      setDSType('pdfFile')
      setPdfFile('');
    }
  }

  const [librarydataId, setLibrarydataId] = useState()
  const handleLibraryClick = (idx) => {
    setLibrarydataId(idx);
    setShowLibs(1);
  }

  const handleAddToResources = (libraryId) => {
    var button = document.getElementById('librarycard_' + libraryId);
    if (button.textContent == 'Add to Resources') {
      button.textContent = 'Remove from Resources';
      http().get('/editlibrary/' + libraryId + '/' + props.idx).then((data) => {
        http().post('/addtomylibrary', {
          id: libraryId,
          type: "library",
          librarydata: "add",
          title: data.data.data.libraryPavilionEditRecord.name,
          image: data.data.data.libraryPavilionEditRecord.image,
          file: data.data.data.libraryPavilionEditRecord.url,
          createdby: data.data.data.libraryPavilionEditRecord.created_by,
          createdat: data.data.data.libraryPavilionEditRecord.created_at,
          updatedat: data.data.data.libraryPavilionEditRecord.updated_at,
          actualid: null,
        }).then((data) => {
          
          Swal.fire({
            title: "Add To Resources is submitted successfully!",
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });
        });
      });

    } else {
      button.textContent = 'Add to Resources';
      http().get('/editlibrary/' + libraryId + '/' + props.idx).then((data) => {
        http().post('/addtomylibrary', {
          id: libraryId,
          type: "library",
          librarydata: "remove",
          title: data.data.data.libraryPavilionEditRecord.name,
          image: data.data.data.libraryPavilionEditRecord.image,
          file: data.data.data.libraryPavilionEditRecord.url,
          createdby: data.data.data.libraryPavilionEditRecord.created_by,
          createdat: data.data.data.libraryPavilionEditRecord.created_at,
          updatedat: data.data.data.libraryPavilionEditRecord.updated_at,
          actualid: 'some',
        }).then((data) => {
          
          Swal.fire({
            title: "Removed From Resources is submitted successfully!",
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });
        });
      });
    }



  }
  const handleRemoveToResources = (libraryId) => {
    var button = document.getElementById('librarycard_' + libraryId);
    if (button.textContent == 'Add to Resources') {
      button.textContent = 'Remove from Resources';
    } else {
      button.textContent = 'Add to Resources';
    }

    http().get('/editlibrary/' + libraryId + '/' + props.idx).then((data) => {
      http().post('/addtomylibrary', {
        id: libraryId,
        type: "library",
        librarydata: "remove",
        title: data.data.data.libraryPavilionEditRecord.name,
        image: data.data.data.libraryPavilionEditRecord.image,
        file: data.data.data.libraryPavilionEditRecord.url,
        createdby: data.data.data.libraryPavilionEditRecord.created_by,
        createdat: data.data.data.libraryPavilionEditRecord.created_at,
        updatedat: data.data.data.libraryPavilionEditRecord.updated_at,
        actualid: 'some',
      }).then((data) => {
        
        Swal.fire({
          title: "Removed To Resources is submitted successfully!",
          icon: "success",
          allowOutsideClick: false,
          confirmButtonColor: "#e30613",
        });
      });
    });
  }

  //input file dialogue

  const onFileChange = (e) => {
    e.preventDefault();
    const dataValue = e.currentTarget.getAttribute("data-value");
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (files.length === 0) {
      return alert("Please select a file.");
    }

    const reader = new FileReader();
    reader.onload = () => {
      // getUploadedFile(reader.result);
      if (dataValue === "LIBRARIMG") { setImagePress2(reader.result); setOpen(true); }
    };

    reader.readAsDataURL(files[0]);
    //setImagePress(e.target.files[0]);
    //setImagePressEdit(e.target.files[0]);

  };

  //input file dialog
  const getCropData = (val) => {
    // do not forget to bind getData in constructor
    setImage(val);
  }

  function handleDropdown(event) {
    event.stopPropagation();
    // console.log('handleChildClick');
  }
  function handleEdit(event) {
    console.log('handleEdit')
  }
  
//Infinite Scroll
const fetchData = async (__page) => {
  console.log(__page);
  const response = await http().get(
       `/library/${props.idx}?page=${page}`).then((response) => {
      // setHasMore(true)
      //console.log(data.data.data.videoPavilionInsertedRecord);
      
       console.log(response.data.data.libraryPavilionRecord.data)
       setPost([...post, ...response.data.data.libraryPavilionRecord.data]);
       setPermissions(response.data.data.permission);
       //setCategory([...category, ...response.data.data.libraryPavilionPublicationCategory.data]);
       const last_page=response.data.data.libraryPavilionRecord.last_page;
   
      if(page < last_page){
        setPage(page + 1);     
        setHasMore(true)
        setLoadMsg(<PreLoader size={PLSIZE} />)
      } else {
        setHasMore(false)
        setLoadMsg('No More Records...')
      }
    });
  };

  return (
    <>

      <ListGroup className="zoom overflow-hidden h-100 px-0 " style={{ borderRadius: "15px" }}>
        <ListGroup.Item key={1000} variant="light" className='d-flex justify-content-between align-items-center  border-0 text-center py-0 '>
          <div>
		  {showLibs === 0 && (<IconButton className='btn btn-outline-mode' onClick={event => props.handleClick('PVLN-MENU', props.idx)}><ArrowBackIcon /></IconButton>)}
          {showLibs > 0 && (<IconButton className='btn btn-outline-mode' onClick={()=>setShowLibs(0)}><ArrowBackIcon /></IconButton>)}
			
            <Typography variant="small">
              {showLibs === 0 && "Library"}
              {showLibs === 1 && "Library Details"}
              {showLibs === 2 && "Search Library"}
            </Typography>
          </div>
          <IconButton className='btn btn-outline-mode' onClick={event => props.handleClick('PVLN-HOME', props.idx)}><Home /></IconButton>
          <div>
            {permissions['create_library_document'] && <IconButton onClick={handleShow}><PostAddIcon /></IconButton>}
            {permissions['search_library_document'] && <IconButton onClick={() => setShowLibs(2)}><FilterAltIcon /></IconButton>}
          </div>
        </ListGroup.Item>
        {showLibs === 0 && (
          <ListGroup.Item key={1001} className="d-flex align-items-start justify-content-center h-100  border-0 bg-light bg-opacity-75">
            <div className="container slim-scrollbar mh-70  py-0 " style={{ overflow: "auto" }}>
<InfiniteScroll
                        pageStart={0}
                        loadMore={fetchData}
                        hasMore={hasMore}
                        // initialLoad={true}
                        // loader={
                        //     <div className="loader" key={0}>
                        //         {loadMsg}
                        //     </div>
                        // }
                        useWindow={false}
                    >
              <Row xs={1} md={2} className="g-1 " >
                {post.map((innerArray, outerIndex) => (
                  <Col key={outerIndex} className="py-2">
                    <Card className="mb-0 px-2 pt-0 pb-2 shadow-sm bg-light" style={{ cursor: "pointer" }} >
                      <Card.Title className="d-flex align-items-center justify-content-between mb-1">
                        <h6 className="ms-0"><CalendarMonthOutlinedIcon /> {innerArray.date}</h6>
<CRUDDropdown title={<MoreVertIcon />} handleDropdown={handleDropdown} editTitle="Edit" editClick={(event) => handleClickEdit(innerArray.id)} deleteTitle="Delete" deleteClick={(event) => handleClickDelete(innerArray.id)}   />
                        </Card.Title>
                        <Image src={localStorage.getItem('bucketName') + '/images/library/' + innerArray.image} sx={{ width: "100%", height: "100%" }} onClick={(event) => handleLibraryClick(innerArray.id)}/>
                        <Card.Body onClick={(event) => handleLibraryClick(innerArray.id)} className="px-0 py-2 d-flex flex-column align-items-start justify-content-between " style={{ minHeight: "63px" }}>
                          <Card.Title className="pt-0 my-0" style={{ lineHeight: "0.7" }}>
                            <Typography key={innerArray.id} variant="large" className="d-flex align-items-center text-dark small" >
                              <div className=""><strong>{innerArray.name}</strong> <br></br>
                                <small className="text-muted">
                                  {category.map((innercategoryArray, outercategoryIndex) => (
                                    <span>
                                      {innercategoryArray.id == innerArray.category_id ? (
                                        <span>Category: {innercategoryArray.name}</span>
                                      ) : (''
                                      )}
                                    </span>
                                  ))}
                                </small>
                              </div>
                            </Typography>
                          </Card.Title>
                        </Card.Body>
                        <Card.Footer className="d-flex justify-content-between align-atems-center px-0 pb-0 pt-2">
                          <Button variant="primary" size="sm" onClick={(event) => handleLibraryClick(innerArray.id)} className="small px-1 py-0"><RemoveRedEyeOutlinedIcon /></Button>
                          <Button onClick={() => handleAddToResources(innerArray.id)} variant="primary" size="sm" className="small px-1 py-0" id={'librarycard_' + innerArray.id}>
                            {(innerArray.addtomyresource == '' || innerArray.addtomyresource == null) ? ("Add to Resources") : ("Remove from Resources")}</Button>
                        </Card.Footer>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </InfiniteScroll>
              <div className="loader my-2 text-center" key={0} >
                {loadMsg}
              </div>
            </div>
          </ListGroup.Item>
        )}
        {showLibs === 1 && (<PVLNLibraryDetails  librarydataId={librarydataId} pavilionId={props.idx}  handleClick={handleClick} />)}
       {showLibs === 2 && (
          <ListGroup.Item key={1002} className=" mx-0 px-0 d-flex align-items-start justify-content-center h-100 border-0 bg-light bg-opacity-75">
            <Container>
              <Form className="mx-0 px-0">
                <Form.Select required size="sm" id="category_id" name="category_id" className="my-1 py-1" onChange={handleLibraryCategoryChangeSearch}>
                  <option value="">All Categories</option>
                  {categoryData.map((innerArray, outerIndex) => (
                    <option value={innerArray.id} >{innerArray.name}</option>
                  ))}
                </Form.Select>
                <Form.Select id="document_type" name="document_type" size="sm" className="my-1 py-1" onChange={handleLibraryDocumentChange}>
                  <option value="">All Document Types</option>
                  {documentData.map((innerArray, outerIndex) => (
                    <option value={innerArray.id} >{innerArray.name}</option>
                  ))}
                </Form.Select>
                <Form.Select id="year" name="year" size="sm" className="my-1 py-1" onChange={handleLibraryYearChange}>
                  <option value="">Search By Year</option>
                  <option value="2025">2025</option>
                  <option value="2024">2024</option>
                  <option value="2023">2023</option>
                  <option value="All">All Time</option>
                </Form.Select>
                <Form.Control size="sm" id="filter" name="filter" value={filter} onChange={handleLibraryTitleChange} type="text" placeholder="Search by Title" className="my-1 py-1" />
                <Button size="sm" onClick={handleClickLibrary}>Search</Button>
              </Form>
            </Container>
          </ListGroup.Item>
        )}
      </ListGroup>
      <Modal show={show} size="lg" backdrop="static" keyboard="false" onHide={handleParentClose} centered>
        <Modal.Header closeButton className="py-1">
          <Modal.Title className="lead" >Library</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type="text" style={{ display: 'none' }} name="libraryidvalue" id="libraryidvalue" value={libraryidvalue} />
          <input type="text" style={{ display: 'none' }} name="categoryhidden" id="categoryhidden" value={categoryhidden} />
          <input type="text" style={{ display: 'none' }} name="doctypehidden" id="doctypehidden" value={doctypehidden} />
          <input type="text" style={{ display: 'none' }} name="libraryImagecheck" id="libraryImagecheck" value={libraryImagecheck} />
          <Row>
            <Col md={4} className="d-flex flex-column justify-content-center">
              <div >
                {/*<Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                  }}
                >
				<Box my={2} >
                    <label htmlFor="contained-button-file">
                      <Input
                        accept="image/*"
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={onFileChange}
                      />
                      <img className='rounded-2' style={{ cursor: "pointer" }} src={image} alt="cropped" height="100%" width="100%" />
                    </label>
  </Box>*/}

                {/* <Upload image={image}
                    getUploadedFile={(image) => {
                      setOpen(true);
                      setImage(image);
                    }}
                  /> 
                  <Popup
                    open={open}
                    handleClose={handleClose}
                    image={image}
                    getCroppedFile={(image) => {
                      setImage(image);
                      handleClose();
                    }}
                    aspectRatio={9 / 13}
                  />
				  </Box>*/}

                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer" }}>
                  <Box my={2} >
                    <label htmlFor="fileBanner1">
                      <CropperInput accept="image/*" data-value="LIBRARIMG" id="fileBanner1" type="file" onChange={onFileChange} />
                      <Image style={{ borderRadius: "10px", cursor: "pointer", width: "100%", height: "100%" }} src={imagePress} />
                    </label>
                  </Box>
                  <CropperPopup
                    open={open} image={imagePress2} handleClose={handleClose}
                    getCroppedFile={(image) => {
                      setImagePress(image)
                      handleClose();
                    }}
                    aspectRatio={9 / 13}
                  />
                </Box>
              </div>
              {errors.imagePress && <div style={{ color: 'red' }}>{errors.imagePress}</div>}

              {/* <div>{output && <img src={output} />}</div> */}

              {/* <img id="libraryBanner" onClick={onImageClick} src={fileUrl} /> */}
              {/* </ReactCrop> */}
              {/* <img className="card-img-top rounded mx-0 py-2" src="https://source.unsplash.com/random/250x335/?sig=0"/> */}
            </Col>
            <Col md={8}>
              <Form.Control required type="text" size="sm" name="library-name" value={libraryname} id="library-name" placeholder="Library Name" className="my-2  py-1" onChange={handleLibraryNameChange} />
              {errors.libraryname && <div style={{ color: 'red' }}>{errors.libraryname}</div>}
              <Form.Control required type="text" as="textarea" rows={3} size="sm" value={librarydescription} name="library-desn" id="library-desn" placeholder="Description" className="my-2  py-1" onChange={handleLibraryDescriptionChange} />
              {errors.librarydescription && <div style={{ color: 'red' }}>{errors.librarydescription}</div>}
              <Form.Select required size="sm" name="librarycategory" id="librarycategory" className="my-2  py-1" onChange={handleLibraryCategoryChange} >
                <option value="">All Categories</option>
                {categoryData.map((innerArray, outerIndex) => (
                  <option selected={librarycategory == innerArray.id} value={innerArray.id} >{innerArray.name}</option>
                ))}
              </Form.Select>
              {errors.librarycategory && <div style={{ color: 'red' }}>{errors.librarycategory}</div>}
              <Form.Select required value={librarydoctype} size="sm" id="doc-type" name="doc-type" className="my-2  py-1" onChange={handleLibraryDocTypeChange} >
                <option value="">All Document Types</option>
                {documentData.map((innerArray, outerIndex) => (
                  <option selected={librarydoctype == innerArray.id} value={innerArray.id} >{innerArray.name}</option>
                ))}
              </Form.Select>
              {errors.librarydoctype && <div style={{ color: 'red' }}>{errors.librarydoctype}</div>}
              {/* <Form.Control type="text" size="sm" name="tags" placeholder="Tags" className="my-2  py-1" /> */}
              <div className='input-group my-2 py-1'>
                {/* <InputTags required placeholder='Tags' size="small" className='p-0 input-sm' value={librarytags} name="tags" id="tags" values={state} onTags={(value) => setState(value.values)}  onChange={handleLibraryTagsChange} /> */}
                <TagsInput value={librarytags} onChange={setLibraryTags} id='tags' name="tags" placeHolder="Tags" />
                {/* <button
                  className='btn btn-outline-secondary btn-sm my-0'
                  type='button'
                  data-testid='button-clearAll'
                  onClick={() => {
                    setLibraryTags([])
                  }}
                >
                  Clear All
                </button> */}
              {errors.librarytags && <div style={{ color: 'red' }}>{errors.librarytags}</div>}
              </div>
              <Form.Group className="d-flex ">
                <Form.Select required onClick={handleDSTypeClick} size="sm" id="doc-source-type" name="doc-source-type" className="my-0  py-1 w-25">
                  <option selected={docsourcetype == 'weblink'} value="weblink">Web Link</option>
                  <option selected={docsourcetype == 'pdfFile'} value="pdfFile">PDF File</option>
                </Form.Select>
                {dsType === 'weblink' ? (<Form.Control type="text" size="sm" id="weblink" value={pdffile} name="weblink" className="my-0  py-1 w-75" onChange={handleLibraryWebLinkChange} />)
                  : (<Form.Control size="sm" type="file" id="pdffile" value={pdffile} name="pdffile" className="my-0  py-1 w-75" accept="application/pdf" onChange={handlePdfFileChange} />)}

              </Form.Group>
              <Form.Control required type="text" onFocus={(e) => { e.target.type = 'date' }} onBlur={(e) => { e.target.type = 'text' }} size="sm" name="pubdate" placeholder="Publication Date" value={librarydate} className="my-2  py-1" onChange={handleLibraryDateChange} />
              {errors.librarydate && <div style={{ color: 'red' }}>{errors.librarydate}</div>}

            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={handleParentClose}>
            Cancel
          </Button>
          <Button size="sm" variant="primary" onClick={handleClickLibrarySubmit}>
            {progress && (<Spinner size='sm' className="me-2" animation="border" />)} {libraryidvalue == 0 ? ('ADD') : ('PUBLISH')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default PVLNLibrary
