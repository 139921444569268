import React, { useState, useEffect } from 'react'
import BadgeAvatar from './BadgeAvatar'
import { Card, Row, Col, Form, Button, Container } from 'react-bootstrap'
import AddPhotoIcon from '@mui/icons-material/AddPhotoAlternate';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import { IconButton } from '@mui/material';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Dropdown from 'react-bootstrap/Dropdown';
import CRUDDropdown from './CRUDDropdown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ThumbUpIcon from '@mui/icons-material/ThumbUpAlt';
import ReplyIcon from '@mui/icons-material/Reply';
import Badge from 'react-bootstrap/Badge';
import CommentsInput from './CommentsInput';
import Comments2 from './Comments/Comments2';
import http, { baseUrl } from '../http';
import ListGroup from 'react-bootstrap/ListGroup';
import Swal from 'sweetalert2'
import { PLSIZE, formatDate } from './PavilionConstants';


const Comments = (props) => {
  console.log(props)
  const [cmt, setCmt] = useState('')
  const [replyCount, setReplyCount] = useState(0)
  const [thumbCount, setThumbCount] = useState(0)
  const [showCommentInput, setShowCommentInput] = useState(false)
  const [commentData, setCommentData] = useState([])
  const [postIdData, setPostIdData] = useState([])
  const [open, setOpen] = useState({});
  const [open3, setOpen3] = useState(false);
  const [commentInput, setCommentInput] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [imagePress2, setImagePress2] = useState('');
  const [CommentUsername, setUserName] = useState('');
  const [CommentUserimage, setUserImage] = useState('');

  const styleReply = {
    visibility: 'visible'
  };
  const divStyleNone = {
    display: 'none',
  };

  function handleEmoji(e) {
    setCmt(cmt + e.target.value)

  }

  function handleSubCommentEmoji(e) {
    setCommentInput(commentInput + e.target.value)
  }

  function handleDeleteComment(e) {

  }
  function handleReplyClick(e) {
    setReplyCount(replyCount + 1)
    setShowCommentInput(true);
  }
  function commentLike(id, type) {
    http().post('/commentlikes', {
      type: type,
      post_id: id,
    }).then((data) => {
      console.log(data);
      setCmt('')
      setCommentInput('');
      http().get('/singlewallpost/' + props.pavilionId + '/' + props.postId + '/' + props.type).then((data) => {
        setCommentData(data.data.data.post.comments)
        setPostIdData(data.data.data.post.id)
      });
    });
  }
  function handleReplySubCommentClick(id, type) {
    // document.getElementById('subcommentinput' + id).style.display = 'block';
    var element = document.getElementById('subcommentinput' + id);
    if (element.style.display === 'none' || element.style.display === '') {
      element.style.display = 'block';
    } else {
      element.style.display = 'none';
    }

  }

  useEffect(() => {

    http().get('/userdetails').then((data) => {
      setUserName(data.data.data.user.user_name);
      setUserImage(data.data.data.user.image);
      console.log('user')
      //console.log(data.data.data.user)
    });

    console.log('yes');
    console.log(props.postId);
    console.log(props.pavilionId);
    http().get('/singlewallpost/' + props.pavilionId + '/' + props.postId + '/' + props.type).then((data) => {
      /*console.log(data);
      console.log(data.data);
      console.log(data.data.data);
      console.log('y1')
      console.log(data.data.data.post);*/
      console.log('y2')
      //setCommentData(data.data.data.post.comments);
      setCommentData(data.data.data.post.comments)
      setPostIdData(data.data.data.post.id)
      // console.log(data.data.data.post.comments)
      // console.log(data.data.data.post.comments['0'].user)
      // console.log(data.data.data.post.comments['0'].user.details)
      // console.log(data.data.data.post.comments['0'].user.details.image)
      // console.log('y3')
    });
  }, []);


  const onFileSubCommentChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (files.length === 0) {
      return alert("Please select a file.");
    }
    const reader = new FileReader();
    reader.onload = () => {
      // setImagePress2(reader.result);
      http().post('/webcommentstore/store', {
        post_type: props.type,
        comments: '',
        post_id: props.postId,
        parent_id: e.target.name,
        comment_type: 'photo',
        pictureComment: reader.result,
      }).then((data) => {
        document.getElementById('subcommentinput' + e.target.name).style.display = 'none';
        console.log(data);
        console.log('vvvv')
        console.log(reader.result)
        console.log('vvvv')
        setCmt('')
        setCommentInput('');
        http().get('/singlewallpost/' + props.pavilionId + '/' + props.postId + '/' + props.type).then((data) => {
          setCommentData(data.data.data.post.comments)
          setPostIdData(data.data.data.post.id)
        });
      });
    };
    reader.readAsDataURL(files[0]);
    // setImagePress2(e.target.files[0]);
    //  reader.readAsDataURL(files[0]);
    // console.log(reader.result)   
  };


  const onFileChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (files.length === 0) {
      return alert("Please select a file.");
    }
    const reader = new FileReader();
    reader.onload = () => {
      // setImagePress2(reader.result);
      http().post('/webcommentstore/store', {
        post_type: props.type,
        comments: '',
        post_id: props.postId,
        parent_id: 0,
        comment_type: 'photo',
        pictureComment: reader.result,
      }).then((data) => {
        console.log(data);
        console.log('vvvv')
        console.log(reader.result)
        console.log('vvvv')
        setCmt('')
        setCommentInput('');
        http().get('/singlewallpost/' + props.pavilionId + '/' + props.postId + '/' + props.type).then((data) => {
          setCommentData(data.data.data.post.comments)
          setPostIdData(data.data.data.post.id)
        });
      });
    };
    reader.readAsDataURL(files[0]);
    // setImagePress2(e.target.files[0]);
    //  reader.readAsDataURL(files[0]);
    // console.log(reader.result)   
  };



  const users = [
    { id: 1, name: 'Alice' },
    { id: 2, name: 'Bob' },
    { id: 3, name: 'Charlie' }
  ];

  const handleKeyPressSubcomment = (event) => {
    if (event.key === 'Enter') {
      // alert(event.target.id)
      console.log('hi1');
      console.log(event)
      console.log('hi1.1');
      console.log(props)
      console.log(props.type)
      console.log(props.postId);
      console.log(event.target.value)
      console.log('hi2')

      http().post('/comments/store', {
        comment_type: "text",
        post_type: props.type,
        comments: event.target.value,
        post_id: props.postId,
        parent_id: event.target.id,
      }).then((data) => {
        console.log(data);
        setCmt('')
        setCommentInput('');
        http().get('/singlewallpost/' + props.pavilionId + '/' + props.postId + '/' + props.type).then((data) => {
          setCommentData(data.data.data.post.comments)
          setPostIdData(data.data.data.post.id)
          document.getElementById(event.target.id).value = '';
          document.getElementById('subcommentinput' + event.target.id).style.display = 'none';
          document.getElementById('postCommentcount' + props.postId).innerHTML = data.data.data.post.all_comments.length;
        });
      });
    }
  }

  const handleClickDeleteComment = (id) => {
    Swal.fire({
      title: "Are you sure to delete this comment?",
      text: "You won't be able to revert this!",
      icon: "warning",
      width: 'auto',
      showCancelButton: true,
      confirmButtonColor: "#e30613",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        http().post('/delete_resource_comment', {
          id: id,
          type: props.type,
          pavilion_id: props.idx,
        }).then((data) => {
          Swal.fire({
            title: "Post is deleted successfully",
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });
          http().get('/singlewallpost/' + props.pavilionId + '/' + props.postId + '/' + props.type).then((data) => {
            setCommentData(data.data.data.post.comments)
            setPostIdData(data.data.data.post.id)
            console.log(data.data.data.post.comments);
            console.log(data.data.data.post.all_comments.length);
            document.getElementById('postCommentcount' + props.postId).innerHTML = data.data.data.post.all_comments.length;
          });
        });
      }
    });
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      console.log('hi1');
      console.log(event)
      console.log('hi1.1');
      console.log(props)
      console.log(props.type)
      console.log(props.postId);
      console.log(event.target.value)
      console.log('hi2')

      http().post('/comments/store', {
        comment_type: "text",
        post_type: props.type,
        comments: event.target.value,
        post_id: props.postId,
        parent_id: 0,
      }).then((data) => {
        console.log(data);
        setCmt('')
        setCommentInput('');
        http().get('/singlewallpost/' + props.pavilionId + '/' + props.postId + '/' + props.type).then((data) => {
          setCommentData(data.data.data.post.comments)
          setPostIdData(data.data.data.post.id)
          document.getElementById('postCommentcount' + props.postId).innerHTML = data.data.data.post.all_comments.length;
        });
      });
    }
  }

  return (<>
    {/*<Container>
<Comments2 />
</Container>*/}
    <ListGroup key='1234' className="ms-1 mt-1 px-0 ">
      <ListGroup.Item className='border-0 py-0 px-0'>
        <Container className='px-0'>
          {commentData.length > 0 && (commentData.map(commentInfo => (
            <Container className='px-0 mb-2 border-1 border-bottom'>
              <Row className='mb-2'>
                <Col className='d-flex align-items-center justify-content-between'>
                  <div className='d-flex align-items-center justify-content-start'>
                    <BadgeAvatar isLoggedIn={false} src={localStorage.getItem('bucketName') + "/images/profile_picture/" + commentInfo.user.details.image} name={commentInfo.user.name} size='30' />
                    <div>
                      <h6 className='ps-1 my-0 lh-1'>{commentInfo.user.name}</h6>
                      <small className='text-muted ps-1'>{formatDate(commentInfo.created_at)}</small>
                    </div>
                  </div>
                  <small className='ps-1'>
                    <IconButton className='p-1' onClick={() => commentLike(`${commentInfo.id}`, `${props.type}`)} size="small"><ThumbUpIcon fontSize="small" /></IconButton>
                    {commentInfo.likes.length > 0 && (<Badge bg="primary">{commentInfo.likes.length}</Badge>)}
                    <IconButton className='p-1' onClick={() => handleReplySubCommentClick(`${commentInfo.id}`, `${props.type}`)} size="small"><ReplyIcon fontSize="small" /></IconButton>
                    {/* <span><a href="javascript:void(0);" class="reply-button  d-flex-mi"><i class="material-icons fill md-18 " onClick={handleReplySubCommentClick} id={commentInfo.id} style={styleReply} >reply</i></a></span> */}
                    {commentInfo.children.length > 0 && (<Badge bg="primary">{commentInfo.children.length}</Badge>)}
                  </small>

                  <CRUDDropdown title={<MoreVertIcon fontSize="small" />} deleteTitle="Delete" deleteClick={(event) => handleClickDeleteComment(commentInfo.id)} />
                </Col>
              </Row>
              <Row>
                <div>

                  <p className='mb-2 lead' dangerouslySetInnerHTML={{ __html: commentInfo.comments ? commentInfo.comments : '' }}></p>
                  <div id={'subcommentinput' + commentInfo.id} style={{ display: isVisible ? 'block' : 'none' }} className="write-comment  mx-1 my-1  justify-content-between align-items-center px-0">
                    <BadgeAvatar isLoggedIn={false} src={localStorage.getItem('bucketName') + "/images/profile_picture/" + CommentUserimage} name={CommentUsername} size='25' />
                    <div className="input-holder w-100 ps-2 pe-0 d-flex justify-content-between align-items-center">
                      <Form.Control value={commentInput}
                        onKeyPress={handleKeyPressSubcomment}
                        onChange={(e) => { setCommentInput(e.target.value); }} id={commentInfo.id} name="commendsField"
                        size="small" className='py-0' type="text" placeholder="Write a comment..." />
                      <label htmlFor="contained-button-file">
                        <input
                          accept="image/*"
                          id="contained-button-file"
                          datavalue={commentInfo.id}
                          type="file"
                          className='d-none'
                          name={commentInfo.id}
                          onChange={onFileSubCommentChange}
                        />
                        <Button as="span" size="sm " variant="outlined-secondary" className="d-flex align-items-center p-1 " ><AddPhotoIcon fontSize="small" /></Button>
                      </label>
                      <Dropdown >
                        <Dropdown.Toggle as={IconButton} size="small" id="dropdown-split-basic" ><AddReactionIcon /></Dropdown.Toggle>
                        <Dropdown.Menu >
                          <div className='d-flex align-items-center flex-wrap justify-content-center'>
                            <IconButton value="😁" onClick={handleSubCommentEmoji} size="small">😀</IconButton>
                            <IconButton value="😁" onClick={handleSubCommentEmoji} size="small">😁</IconButton>
                            <IconButton value="😂" onClick={handleSubCommentEmoji} size="small">😂</IconButton>
                            <IconButton value="😃" onClick={handleSubCommentEmoji} size="small">😃</IconButton>
                            <IconButton value="😄" onClick={handleSubCommentEmoji} size="small">😄</IconButton>
                            <IconButton value="😅" onClick={handleSubCommentEmoji} size="small">😅</IconButton>
                            <IconButton value="😆" onClick={handleSubCommentEmoji} size="small">😆</IconButton>
                            <IconButton value="😇" onClick={handleSubCommentEmoji} size="small">😇</IconButton>
                            <IconButton value="😈" onClick={handleSubCommentEmoji} size="small">😈</IconButton>
                            <IconButton value="😉" onClick={handleSubCommentEmoji} size="small">😉</IconButton>
                            <IconButton value="😋" onClick={handleSubCommentEmoji} size="small">😋</IconButton>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <ListGroup key='1234' className="ms-1 mt-1 px-0">
                    <ListGroup.Item className='border-0 py-0'>
                      {commentInfo.children.length > 0 && (commentInfo.children.map(subCommentInfo => (
                        <>
                          <Row>
                            <Col className='d-flex align-items-center justify-content-between'>
                              <div className='d-flex align-items-center justify-content-between'>
                                <BadgeAvatar isLoggedIn={false} src={localStorage.getItem('bucketName') + "/images/profile_picture/" + subCommentInfo.user.details.image} name={subCommentInfo.user.name} size='25' />
                                <h6 className='ps-1 my-0'>{subCommentInfo.user.name}</h6>
                                <small className='text-muted ps-1'>{formatDate(subCommentInfo.created_at)}</small>
                              </div>
                              <CRUDDropdown title={<MoreVertIcon fontSize="small" />} deleteTitle="Delete" deleteClick={(event) => handleClickDeleteComment(subCommentInfo.id)} />
                            </Col>
                          </Row>
                          <Row>
                            <p className='mb-2 mt-1 lead' dangerouslySetInnerHTML={{ __html: subCommentInfo.comments ? subCommentInfo.comments : '' }}></p>
                          </Row>
                        </>
                      )))}
                    </ListGroup.Item>
                  </ListGroup>
                  <div>
                  </div>
                </div>
              </Row>
            </Container>
          )))}
        </Container>
      </ListGroup.Item>
    </ListGroup>
    <div className="write-comment  my-2 d-flex justify-content-between align-items-center">
      <BadgeAvatar isLoggedIn={false} src={localStorage.getItem('bucketName') + "/images/profile_picture/" + CommentUserimage} name={CommentUsername} size='25' />
      <div className="input-holder w-100 ps-2 pe-0 d-flex justify-content-between align-items-center">
        <Form.Control onChange={(e) => setCmt(e.target.value)}
          datatype="post"
          datapostId={props.postId}
          dataid={postIdData} onKeyPress={handleKeyPress} id={postIdData} value={cmt} name="commendsField" size="small" className='py-0' type="text" placeholder="Write a comment..." />
        <label htmlFor="contained-button-file">
          <input
            accept="image/*"
            id="contained-button-file"
            type="file"
            className='d-none'
            onChange={onFileChange}
          />
          <input type='text' value={imagePress2} name="maincommentfile" id="maincommentfile" style={divStyleNone} />
          <IconButton as="span" size="small" style={{ cursor: 'pointer' }} variant="secondary" ><AddPhotoIcon /></IconButton>
        </label>
        <Dropdown >
          <Dropdown.Toggle as={IconButton} size="small" id="dropdown-split-basic" ><AddReactionIcon /></Dropdown.Toggle>
          <Dropdown.Menu >
            <div className='d-flex align-items-center flex-wrap justify-content-center'>
              <IconButton value="😁" onClick={handleEmoji} size="small">😀</IconButton>
              <IconButton value="😁" onClick={handleEmoji} size="small">😁</IconButton>
              <IconButton value="😂" onClick={handleEmoji} size="small">😂</IconButton>
              <IconButton value="😃" onClick={handleEmoji} size="small">😃</IconButton>
              <IconButton value="😄" onClick={handleEmoji} size="small">😄</IconButton>
              <IconButton value="😅" onClick={handleEmoji} size="small">😅</IconButton>
              <IconButton value="😆" onClick={handleEmoji} size="small">😆</IconButton>
              <IconButton value="😇" onClick={handleEmoji} size="small">😇</IconButton>
              <IconButton value="😈" onClick={handleEmoji} size="small">😈</IconButton>
              <IconButton value="😉" onClick={handleEmoji} size="small">😉</IconButton>
              <IconButton value="😋" onClick={handleEmoji} size="small">😋</IconButton>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>

  </>
  )
}

export default Comments
