import React, { useState, useEffect } from 'react'
import PVLNHome from './RightSection/PVLNHome'
import PVLNMenu from './RightSection/PVLNMenu'
import PVLNAbout from './RightSection/PVLNAbout'
import PVLNMembers from './RightSection/PVLNMembers'
import PVLNShop from './RightSection/PVLNShop'
import PVLNWallPosts from './RightSection/PVLNWallPosts'
import PVLNPhotos from './RightSection/PVLNPhotos'
import PVLNVideos from './RightSection/PVLNVideos'
import PVLNEvents from './RightSection/PVLNEvents'
import PVLNPublications from './RightSection/PVLNPublications'
import PVLNLibrary from './RightSection/PVLNLibrary'
import PVLNWallGallery from './RightSection/PVLNWallGallery'
import PVLNPolls from './RightSection/PVLNPolls'
import PVLNPress from './RightSection/PVLNPress'
import { useSearchParams } from "react-router-dom";
import { useHistory, useLocation } from 'react-router-dom';
import { LocalTaxiOutlined } from '@mui/icons-material'
import { useNavigate } from "react-router-dom"
import PUBLPostDetails from '../../../components/pavilion/Boxes/RightSection/Public/PUBLPostDetails'
import PUBLEventDetails from './RightSection/Public/PUBLEventDetails'
import PUBLPublicationDetails from './RightSection/Public/PUBLPublicationDetails'
import PUBLPressDetails from './RightSection/Public/PUBLPressDetails'
import PRFLPublicPavilion from './LeftSection/Public/PRFLPublicPavilion'
import PRFLNewPublicPavilion from './RightSection/Public/PRFLNewPublicPavilion'
import PUBLPollDetails from './RightSection/Public/PUBLPollDetails'
import PRFLWhosWho from './LeftSection/PRFLWhosWho'
import PRFLSupporters from './LeftSection/Public/PRFLSupporters';

function RightBox(props) {
  const navigate = useNavigate();
  // const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation()

  const [value, setNewValue] = useState("");
  const [pvlnIndex, setPvlnIndex] = useState(1);
  const [showPVLN, setShowPVLN] = useState('PVLN-HOME');

  const splitURL = location.pathname.toString().split("/");

  useEffect(() => {
    if (splitURL[1] === 'preview') {
      if (splitURL[2]) {
        setShowPVLN('PUBL-NEWPVLNS')
      }
    } else if (splitURL[1] === 'about_pavilion') {
      if (splitURL[2]) {
        setShowPVLN('PUBL-PVLNS')
      }
    } else if (splitURL[1] === 'shareprofiles') {
      if (splitURL[2]) {
        setShowPVLN('PUBL-PVLNS')
      }
    } else if (splitURL[1] === 'shop') {
      if (splitURL[2]) {
        setShowPVLN('PVLN-SHOPP')
      }
    } else if (splitURL[1] === 'newsinformation') {
      if (splitURL[2]) {
        setShowPVLN('PUBL-PRESS')
      }
    } else if (splitURL[1] === 'publication') {
      if (splitURL[2]) {
        setShowPVLN('PUBL-PUBLS')
      }
    } else if (splitURL[1] === 'event') {
      if (splitURL[2]) {
        setShowPVLN('PUBL-EVNTS')
      }
    } else if (splitURL[1] === 'walls') {
      if (splitURL[2]) {
        setShowPVLN('PUBL-WPOST')
      }
    } else if (splitURL[1] === 'poll') {
      if (splitURL[2]) {
        setShowPVLN('PUBL-POLLS')
      }
    } else if (props.pvlnCode === 'PVLN-HOME') {
      navigate(`/homepavilion`);
    } else if (props.pvlnCode === 'PVLN-SHOPP') {
      setShowPVLN('PVLN-SHOPP');
    } else if (props.pvlnCode === 'PVLN-MENU') {
      setShowPVLN('PVLN-MENU');
    }

  }, [showPVLN]);


  useEffect(() => {
    if (props.pavilion) {
      setShowPVLN('PVLN-MENU');
      setPvlnIndex(props.pvlnIndex)
    } 
  }, [props.pavilion,props.pvlnIndex]);


  const handleClick = (count, idx) => {
    props.change(count, idx);
    setPvlnIndex(idx);
    setShowPVLN(count);
    //        window.history.replaceState(null, '', '/homepavilion');

  }
  return (
    <>
      {showPVLN === 'PVLN-HOME' && (<PVLNHome idx={pvlnIndex} handleClick={handleClick} />)}
      {showPVLN === 'PVLN-MENU' && (<PVLNMenu idx={pvlnIndex} handleClick={handleClick} />)}

      {showPVLN === 'PVLN-ABOUT' && (<PVLNAbout idx={pvlnIndex} handleClick={handleClick} />)}
      
      {pvlnIndex===10 ? (<>
        {showPVLN === 'PVLN-MMBRS' && (<PRFLWhosWho idx={pvlnIndex} handleClick={handleClick} />)}  
        </>
      )
      :(<>
        {showPVLN === 'PVLN-MMBRS' && (<PVLNMembers idx={pvlnIndex} handleClick={handleClick} />)}
        </>
      )}
      
      {showPVLN === 'PVLN-EVNTS' && (<PVLNEvents idx={pvlnIndex} handleClick={handleClick} />)}
      {showPVLN === 'PVLN-POLLS' && (<PVLNPolls idx={pvlnIndex} handleClick={handleClick} />)}
      {showPVLN === 'PVLN-WPOST' && (<PVLNWallPosts idx={pvlnIndex} handleClick={handleClick} />)}
      {showPVLN === 'PVLN-WGALL' && (<PVLNWallGallery idx={pvlnIndex} handleClick={handleClick} />)}
      {showPVLN === 'PVLN-WPUBS' && (<PVLNPublications idx={pvlnIndex} handleClick={handleClick} />)}
      {showPVLN === 'PVLN-LIBRY' && (<PVLNLibrary idx={pvlnIndex} handleClick={handleClick} />)}
      {showPVLN === 'PVLN-VID0S' && (<PVLNVideos idx={pvlnIndex} handleClick={handleClick} />)}
      {showPVLN === 'PVLN-PHOTO' && (<PVLNPhotos idx={pvlnIndex} handleClick={handleClick} />)}
      {showPVLN === 'PVLN-PRESS' && (<PVLNPress idx={pvlnIndex} handleClick={handleClick} />)}
      {showPVLN === 'PVLN-SHOPP' && (<PVLNShop idx={pvlnIndex} handleClick={handleClick} />)}
      {showPVLN === 'PVLN-SUPPO' && (<PRFLSupporters idx={pvlnIndex} handleClick={handleClick} />)}

      {showPVLN === 'PUBL-WPOST' && (<PUBLPostDetails idx={pvlnIndex} handleClick={handleClick} />)}
      {showPVLN === 'PUBL-EVNTS' && (<PUBLEventDetails idx={pvlnIndex} handleClick={handleClick} />)}
      {showPVLN === 'PUBL-PUBLS' && (<PUBLPublicationDetails idx={pvlnIndex} handleClick={handleClick} />)}
      {showPVLN === 'PUBL-PRESS' && (<PUBLPressDetails idx={pvlnIndex} handleClick={handleClick} />)}
      {showPVLN === 'PUBL-POLLS' && (<PUBLPollDetails idx={pvlnIndex} handleClick={handleClick} />)}
      {showPVLN === 'PUBL-PVLNS' && (<PRFLPublicPavilion idx={pvlnIndex} handleClick={handleClick} />)}
      {showPVLN === 'PUBL-NEWPVLNS' && (<PRFLNewPublicPavilion idx={pvlnIndex} handleClick={handleClick} />)}

    </>
  )
}

export default RightBox