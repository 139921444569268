import React, { useEffect,useState } from 'react'
import InfiniteScroll from "react-infinite-scroller";
import ListGroup from 'react-bootstrap/ListGroup';
import { Card, Row, Col, Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import { AccountBalanceOutlined } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete';
// import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SvgIcon } from '@mui/material';
import { Home, Search } from "@mui/icons-material";
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FsLightbox from "fslightbox-react";
import axios from "axios";
import AddPhotoIcon from '@mui/icons-material/AddPhotoAlternate';
import PhotoAlbumIcon from '@mui/icons-material/PhotoAlbumOutlined';
import { Modal, Image } from 'react-bootstrap';
import http from '../../../../http';
import Swal from 'sweetalert2'
import PreLoader from '../../../PreLoader'
import {PLSIZE} from '../../../PavilionConstants';
function PVLNPhotos(props, {handleClick}) {
	
 const [hasMore, setHasMore] = useState(true);
  const [albums, setAlbums] = useState([]);
  const [page, setPage] = useState(1);
  const [loadMsg, setLoadMsg] = useState(<PreLoader size={PLSIZE}/>);
  const [permissions, setPermissions] = useState({});
const [userdetails, setUserdetails] = useState([]);
const [post, setPost] = useState([]);
const [category, setCategory] = useState([]);
const libraryImagePath = localStorage.getItem('bucketName')+'/images/';
const libraryImagePathStatic = localStorage.getItem('bucketName')+'/images/noimage.webp';

  
	useEffect(() => {
	http().get('/userdetails').then((data) => {
		setUserdetails(data.data.data.user);
	});
	}, []);
	
	const [showPhotos,setShowPhotos]=useState(false)
	const [open, setOpen] = React.useState(false);
    const [show,setShow]=useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
	
	const [photoplatformurl, setPhotoplatformurl] = useState([]);

	const handlePhotoSubmit =(e)=>{
	http().post('/albumstore', {
			pavilion_id:props.idx,
			publication_state:"create",
			name:document.getElementById('photoplatformurl').value,
	}).then((data) => {
		setShow(false);
		
    Swal.fire({
      title: "Record added successfully!",
      icon: "success",
      allowOutsideClick: false,
      confirmButtonColor: "#e30613",
    });
		 http().get('/albumimages/'+props.idx).then((data) => {
      setPost(data.data.data.flickrAlbumPavilionRecord);
    });
	});
	}
	const handleDeleteAlbum =(e, id, albumId)=>{
    e.stopPropagation();
    Swal.fire({
      title: "Are you sure to delete this Album?",
      text: "You won't be able to revert this!",
      icon: "warning",
      width: 'auto',
      showCancelButton: true,
      confirmButtonColor: "#e30613",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        http().post('/delete_resource_video', {
          pavilion_id:props.idx,
          id:id,
          type:"flickralbum",
          data_name_id:albumId,
      }).then((data) => {
        
        Swal.fire({
          title: "Your file has been deleted.",
          icon: "success",
          allowOutsideClick: false,
          confirmButtonColor: "#e30613",
        });
         http().get('/albumimages/'+props.idx).then((data) => {
          setPost(data.data.data.flickrAlbumPavilionRecord);
        });
        setShow(false);
      });
        
      }
    });

    
	  
	  
	}
	const [albumPhotosData, setAlbumPhotosData] = useState([]);
  const [albumPhotosData2, setAlbumPhotosData2] = useState([]);
	const handleCardImage = (albumId) => {
    albumPhotosData2.length=0;
	 http().get('/photos/'+props.idx+'/'+albumId).then((data) => {
    
    console.log(data.data.data.flickrAlbumsImagePavilionRecord.data) ;
    setAlbumPhotosData(data.data.data.flickrAlbumsImagePavilionRecord.data);
    for (let i = 0; i < data.data.data.flickrAlbumsImagePavilionRecord.data.length; i++) {
      albumPhotosData2.push('https://farm'+data.data.data.flickrAlbumsImagePavilionRecord.data[i].farm+'.staticflickr.com/'+data.data.data.flickrAlbumsImagePavilionRecord.data[i].server+'/'+data.data.data.flickrAlbumsImagePavilionRecord.data[i].photo_id+'_'+data.data.data.flickrAlbumsImagePavilionRecord.data[i].secret+'_c.jpg');
  }
    
    });
    
	setShowPhotos(1);
  }
  
	/*useEffect(() => {
	  http().get('/albumimages/'+props.idx).then((data) => {
      setPost(data.data.data.flickrAlbumPavilionRecord.data);
    });
	}, []);*/
  
  
//Infinite Scroll
  const fetchData = async (__page) => {
    console.log(__page);
    const response = await 
      http().get(`/albumimages/${props.idx}?page=${__page}`).then((response) => {
       // setHasMore(true)
         console.log(response.data.data.flickrAlbumPavilionRecord)
         setPost([...post, ...response.data.data.flickrAlbumPavilionRecord]);
         setPermissions(response.data.data.permission);
         const last_page=response.data.data.flickrAlbumPavilionRecord.last_page;
     
         if (__page < last_page) {
          setPage(__page + 1);
          setHasMore(true);
          setLoadMsg(<PreLoader size={PLSIZE} />);
      } else {
          setHasMore(false);
          setLoadMsg('No More Records...');
      }
    })
  };
  	
  
  return (
    <>
    <ListGroup className="zoom overflow-hidden h-100 px-0 " style={{ borderRadius: "15px" }}>
            <ListGroup.Item variant="light" className='d-flex justify-content-between align-items-center  border-0 text-center py-0 '>
            <div>
				{!showPhotos ? (<IconButton className='btn btn-outline-mode'  onClick={event => props.handleClick('PVLN-MENU',props.idx)}><ArrowBackIcon /></IconButton>) : (<IconButton onClick={()=>setShowPhotos(0)}><ArrowBackIcon /></IconButton>)}
				
                <Typography variant="small">
                 {!showPhotos ? ('Flickr Albums') : ('Flickr Album Photos')}
                </Typography>
                </div>
                <IconButton className='btn btn-outline-mode' onClick={event => props.handleClick('PVLN-HOME',props.idx)}><Home /></IconButton>
				{!showPhotos ? (<IconButton   onClick={event => props.handleClick('PVLN-MENU',props.idx)}><ArrowBackIcon /></IconButton>) : (<IconButton onClick={()=>setShowPhotos(0)}><ArrowBackIcon /></IconButton>)}
        {permissions['create_flickr_album'] && <IconButton className='btn btn-outline-mode'  onClick={handleShow}><AddPhotoIcon /></IconButton>}
               
            </ListGroup.Item>
            <ListGroup.Item className="d-flex align-items-start justify-content-center h-100  border-0 bg-light bg-opacity-75">
                <div className="container slim-scrollbar mh-70  py-0 " style={{ overflow: "auto" }}>
                
				
                <Row xs={1} md={1} className="g-0 " >
	   {!showPhotos ? (<>
	     <InfiniteScroll
                        pageStart={0}
                        loadMore={fetchData}
                        hasMore={hasMore}
                        // initialLoad={true}
                        // loader={
                        //     <div className="loader" key={0}>
                        //         {loadMsg}
                        //     </div>
                        // }
                        useWindow={false}
                    >
       {post.map((innerArray, outerIndex) => (
          <Col key={innerArray.user_id} className="py-2">
            <Card className="mb-0 shadow-sm bg-light" style={{cursor:"pointer"}} onClick={(event) => handleCardImage(innerArray.photosetId)}>
             <Card.Img variant="top" src={'https://farm'+innerArray.farm+'.staticflickr.com/'+innerArray.server+'/'+innerArray.primary+'_'+innerArray.secret+'.jpg'}      />
             <Card.ImgOverlay>
             <IconButton className="text-light float-start"><PhotoAlbumIcon fontSize="large" /></IconButton>
               <IconButton className="text-primary float-end bg-light" onClick={(e)=>handleDeleteAlbum(e,innerArray.id, innerArray.photosetId)}><DeleteIcon /></IconButton>
               
             </Card.ImgOverlay>
         {/*<Card.ImgOverlay>
             <IconButton className="text-light float-start"><PhotoAlbumIcon fontSize="large" /></IconButton>
               <IconButton className="text-primary float-end bg-light" onClick={handleDeleteAlbum}><DeleteIcon /></IconButton>
               
	   </Card.ImgOverlay> */}
              <Card.Body className="p-2 d-flex flex-column align-items-center justify-content-between " style={{minHeight:"75px"}}>

              <Card.Title className="pt-0 my-0" style={{lineHeight:"0.7"}}>
                <Typography key={innerArray.user_id} variant="large" className="fw-bold text-dark small" >
                                       <span  onClick={(event) => handleCardImage(innerArray.photosetId)}> {innerArray.title}</span>   
                                       {/* <IconButton className="text-primary float-end bg-light"  onClick={(e)=>handleDeleteAlbum(e,innerArray.id, innerArray.photosetId)} ><DeleteIcon /></IconButton>            */}
             
                </Typography>
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>
      ))}
	  
	   </InfiniteScroll>
      <div className="loader my-2 text-center" key={0} >
                                 {loadMsg}
                         </div>
						 
      <Modal size="lg" backdrop="static" keyboard={false} show={show} onHide={handleClose} centered>
        <Modal.Header closeButton className='py-1'>
          <Modal.Title className="lead">Add Flickr Album for Big Innovation Centre</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={3}>Flickr Album Url
</Col>
            <Col md={9}>
              <Form.Control
                required
				id="photoplatformurl"
				name="photoplatformurl"
				value={''}
                size="sm"
                type="text"
                placeholder='https://www.flickr.com/photos/big-innovation-centre/albums/72177720300036688'
				
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button size="sm" variant="primary" onClick={handlePhotoSubmit}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      </>) : (


<Row xs={1} md={1} className="g-0 " >
<InfiniteScroll
                        pageStart={0}
                        loadMore={fetchData}
                        hasMore={hasMore}
                        initialLoad={true}
                        // loader={
                        //     <div className="loader" key={0}>
                        //         {loadMsg}
                        //     </div>
                        // }
                        useWindow={false}
                    >
{albumPhotosData.map((innerArray, outerIndex) => (
<Col key={innerArray.user_id} className="py-2">
            <Card className="mb-0 shadow-sm bg-light" style={{cursor:"pointer"}} onClick={() => setOpen(!open)}>
             <Card.Img variant="top" src={'https://farm'+innerArray.farm+'.staticflickr.com/'+innerArray.server+'/'+innerArray.photo_id+'_'+innerArray.secret+'_c.jpg'}  />
              <Card.Body className="p-2 d-flex flex-column align-items-center justify-content-between " style={{minHeight:"75px"}}>
              <Card.Title className="pt-0 my-0" style={{lineHeight:"0.7"}}>
                <Typography key={innerArray.user_id} variant="large" className="fw-bold text-dark small" >
                    {innerArray.title}             
                </Typography>
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>
        ))}
        </InfiniteScroll>  
        <div className="loader my-2 text-center" key={0} >
                                 {loadMsg}
                         </div>
</Row>
      )}
    </Row>
          
                               
                </div>
            </ListGroup.Item>
        </ListGroup>
        <FsLightbox
				toggler={open}
				sources={albumPhotosData2}
			/>
      
      </>
  )
}

export default PVLNPhotos