import React, { useState,useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import { Row, Col, Container } from 'react-bootstrap';
import { AccountBalanceOutlined } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete';
// import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SvgIcon } from '@mui/material';
import { Home, Search, Settings } from "@mui/icons-material";
import { IconButton } from '@mui/material';
import myComm from '../../../../assets/icons/mycommunity.svg';
import myPublicProfile from '../../../../assets/icons/mypublicprofile.svg';
import updateProfile from '../../../../assets/icons/updateprofile.svg';
import myPavilions from '../../../../assets/icons/mypavilions.svg';
import whosWho from '../../../../assets/icons/whoswho.svg';
import myEvents from '../../../../assets/icons/myevents.svg';
import myResources from '../../../../assets/icons/myresources.svg';
import myWall from '../../../../assets/icons/mywall.svg';
import myWallGallery from '../../../../assets/icons/mywallgallery.svg';
import purchaseHistory from '../../../../assets/icons/purchasehistory.svg';
import helpSupport from '../../../../assets/icons/helpnsupport.svg';
import info from '../../../../assets/icons/info.svg';
import howTo from '../../../../assets/icons/videos.svg';
import businessServices from '../../../../assets/icons/businessservices.svg';
import signOut from '../../../../assets/icons/signout.svg';
import Spinner from 'react-bootstrap/Spinner';
// import ReactDOM from "react-dom";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

function PRFLMenu1({handleClick}) {
    const navigate = useNavigate();
const [loading, setLoading]=useState(true);
const logout = () => {
    Swal.fire({
        title: "Are you sure?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Logout"
      }).then((result) => {
        if (result.isConfirmed) {
            localStorage.clear();
            navigate('/');
        }
      });
    
}

const location = useLocation();

	//http://127.0.0.1:3000/homepavilion/<pid>/polls/<pollid>
    useEffect(() => {
        const splitURL = location.pathname.toString().split("/");
        if (splitURL[1] === 'shareprofiles') {
            if (splitURL[2] ) {
        	 handleClick('PRFL-MYPPR')
            } 
        }
        
        
    }, [location]);

// componentDidMount(){

// }
useEffect(() => {    
setLoading(false)    
},[])

 return (
    <>
    {loading ? ( <Spinner className="position-relative start-50 top-50 " animation="border" /> ) : (
    
    <ListGroup className="zoom overflow-hidden h-100 px-0 " style={{ borderRadius: "15px" }}>
            <ListGroup.Item variant="light" className='d-flex justify-content-between align-items-center  border-0 text-center py-0 '>
                <Typography variant="small">Profile Management</Typography>
                <IconButton className='p-1 m-1 btn btn-outline-mode' ><Settings /></IconButton>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex align-items-start justify-content-center h-100  border-0 bg-light bg-opacity-75" >
                <div className="container slim-scrollbar mh-70  py-2">
              
              <div className="profile-grid py-2 ">
                    
                    <Button onClick={event => handleClick('PRFL-MYPPR')}  size="sm" variant="light" className="d-flex flex-column small align-items-center border-0 justify-content-end" >
                        <img src={myPublicProfile} className="mb-1" alt="My Public Profile" />
                        <Typography variant="small">My Public Profile</Typography>
                    </Button>
                    
                    <Button onClick={event => handleClick('PRFL-UPPRO')} variant="light" size="sm" className="d-flex flex-column small align-items-center justify-content-end border-0" >
                        <img src={updateProfile} className="mb-1" alt="My Public Profile" />
                        <Typography variant="small">Update Profile</Typography>
                    </Button>
                    <Button onClick={event => handleClick('PRFL-MYCOM')} variant="light" size="sm" className="d-flex small flex-column align-items-center justify-content-end border-0" >
                        <img src={myComm} className="mb-1" alt="My Community" />
                        <Typography variant="small">My Community</Typography>
                    </Button>
                    <Button onClick={event => handleClick('PRFL-MYPAV')} variant="light" size="sm" className="d-flex flex-column small align-items-center justify-content-end border-0" >
                        <img src={myPavilions} className="mb-1" alt="My Pavilion" />
                        <Typography variant="small">My Pavilion</Typography>
                    </Button>
                    <Button onClick={event => handleClick('PRFL-WHOSW')} variant="light" size="sm" className="d-flex flex-column small align-items-center justify-content-end border-0" >
                        <img src={whosWho} className="mb-1" alt="Whos Who" />
                        <Typography variant="small">Who's Who</Typography>
                    </Button>
                    <Button onClick={event => handleClick('PRFL-MYEVE')} variant="light" size="sm" className="d-flex flex-column small align-items-center justify-content-end border-0" >
                        <img src={myEvents} className="mb-1" alt="My Events" />
                        <Typography variant="small">My Events</Typography>
                    </Button>
                    <Button onClick={event => handleClick('PRFL-MYRES')} variant="light" size="sm" className="d-flex flex-column small align-items-center justify-content-end border-0" >
                        <img src={myResources} className="mb-1" alt="My Resources" />
                        <Typography variant="small">My Resources</Typography>
                    </Button>
                    <Button onClick={event => handleClick('PRFL-MYWAL')} variant="light" size="sm" className="d-flex flex-column small align-items-center justify-content-end border-0" >
                        <img src={myWall} className="mb-1" alt="My Wall" />
                        <Typography variant="small">My Wall</Typography>
                    </Button>
                    <Button onClick={event => handleClick('PRFL-MYWAG')} variant="light" size="sm" className="d-flex flex-column small align-items-center justify-content-end border-0" >
                        <img src={myWallGallery} className="mb-1" alt="My Wall Gallery" />
                        <Typography variant="small">My Wall Gallery</Typography>
                    </Button>
                    <Button onClick={event => handleClick('PRFL-PHIST')} variant="light" size="sm" className="d-flex flex-column small align-items-center justify-content-end border-0" >
                        <img src={purchaseHistory} className="mb-1" alt="Purchase History" />
                        <Typography variant="small">Purchase History</Typography>
                    </Button>
                    <Button onClick={event => handleClick('PRFL-HSUPP')} variant="light" size="sm" className="d-flex flex-column small align-items-center justify-content-end border-0" >
                        <img src={helpSupport} className="mb-1" alt="Help n Support" />
                        <Typography variant="small">Help & Support</Typography>
                    </Button>
                    <Button onClick={event => handleClick('PRFL-INFOR')} variant="light" size="sm" className="d-flex flex-column small align-items-center justify-content-end border-0" >
                        <img src={info} className="mb-1" alt="Info" />
                        <Typography variant="small">Info</Typography>
                    </Button>
                    <Button action target="_blank" href="https://www.youtube.com/channel/UCpvImeYUgu-UAHyjRKDaPTA" variant="light" size="sm" className="d-flex flex-column small align-items-center justify-content-end border-0" >
                        <img src={howTo} className="mb-1" alt="How To" />
                        <Typography variant="small">How To</Typography>
                    </Button>
                    <Button onClick={event => navigate('/plans')} variant="light" size="sm" className="d-flex flex-column small align-items-center justify-content-end border-0" >
                        <img src={businessServices} className="mb-1" alt="Launch My Pavilion" />
                        <Typography variant="small">Launch My Pavilion</Typography>
                    </Button>
                    <Button variant="light" size="sm" className="d-flex flex-column small align-items-center justify-content-end border-0" >
                        <img src={signOut} className="mb-1" alt="Sign Out" onClick={logout} />
                        <Typography variant="small" >Sign Out</Typography>
                    </Button>
                </div>
                </div>
                


            </ListGroup.Item>


        </ListGroup>
        
        )}
        </>
  )
}

export default PRFLMenu1
