import React, { useState, useEffect } from 'react'
import InfiniteScroll from "react-infinite-scroller";
import http from '../../../../http';
import ListGroup from 'react-bootstrap/ListGroup';
import { Card, Row, Col, Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import { AccountBalanceOutlined } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete';
// import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SvgIcon } from '@mui/material';
import { Home, Search } from "@mui/icons-material";
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BadgeAvatar from '../../../BadgeAvatar';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PreLoader from '../../../PreLoader';
import { PLSIZE, formatDate } from '../../../PavilionConstants';
import StarIcon from '@mui/icons-material/Star'
import Swal from 'sweetalert2'
import PVLNEventDetails from '../RightSection/PVLNEventDetails';
import InterestedIcon from '@mui/icons-material/EventAvailable';
import NotInterestedIcon from '@mui/icons-material/EventBusy';

function PRFLMyEvents(props, { handleClick }) {

  const [showEvents, setShowEvents] = useState(0);
  const [rowIndex, setRowIndex] = useState();
  //const [showFilter, setShowFilter] = useState(false);

  const [isInterested, setIsInterested] = useState(false);

  const [hasMore, setHasMore] = useState(true);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [loadMsg, setLoadMsg] = useState(<PreLoader size={PLSIZE} />);
  const token = localStorage.getItem('token');
  const [intesetedmsg, setIntesetedmsg] = useState('interested');
  const [unintesetedmsg, setUnintesetedmsg] = useState('uninterested');
  const [intesetedmsgcolor, setIntesetedmsgcolor] = useState('primary');
  const [unintesetedmsgcolor, setUnintesetedmsgcolor] = useState('secondary');
  // const [btnText, setBtnText]=useState(["Current","Know More"])

  // Function to fetch events from an API
  const fetchMyEvents = async () => {
    try {
      const response = await http().get(`/myevents`);
      if (response.status === 200 && response.data?.data?.length > 0) {
        setItems(response.data.data);
        setHasMore(true)
        setLoadMsg(<PreLoader size={PLSIZE} />)
      } else {
        setLoadMsg('No Record Found.');
        setHasMore(false)
      }
    } catch (error) {
      setHasMore(false)
      console.error('Error fetching events:', error);
      setLoadMsg('Error fetching events');
    }

  };

  useEffect(() => {
    fetchData();
  }, []);


  //Infinite Scroll
  const fetchData = async (__page) => {
    console.log(__page);
    const response = await http().get(`/myevents?page=${page}`
    ).then((response) => {
      //  setHasMore(true)
      //  console.log(response.data.data)

      setItems(response.data.data);
      const last_page = response.data.length;

      if (page < last_page) {
        setPage(page + 1);
        setHasMore(true)
        setLoadMsg(<PreLoader size={PLSIZE} />)
      } else {
        setHasMore(false)
        setLoadMsg('No More Records...')
      }
    });
  };

  const handleEventClick = (idx) => {


    setShowEvents(1)
    setRowIndex(idx)

  }
/*  const handleInterestedClick = (id) => {
  if(isInterested){
    setIsInterested(false)
  } else {
    setIsInterested(true)
  }
    http().post('/eventinterest/' + id, {
      event: "interested",
    }).then((response) => {
      Swal.fire({
        title: response.data.data.event,
        icon: "success",
        allowOutsideClick: false,
        confirmButtonColor: '#e30613'
      });
    }).catch((error) => {
      console.error('Error:', error);
      Swal.fire({
        title: 'An error occurred. Please try again.',
        icon: 'error',
      });
    });

  }*/
    const handleInterestedClick = (id,interestedmsgdata) => {
      if(interestedmsgdata=='interested'){
        if(intesetedmsgcolor=='primary'){
          setIntesetedmsgcolor('secondary')
        }else{
          setIntesetedmsgcolor('primary')
        }
      }else{
        if(unintesetedmsgcolor=='primary'){
          setUnintesetedmsgcolor('secondary')
        }else{
          setUnintesetedmsgcolor('primary')
        }
      }
      // if(isInterested){
      //   setIsInterested(false)
      // } else {
      //   setIsInterested(true)
      // }
        http().post('/webeventinterest/' + id, {
          event: "interested",
        }).then((response) => {
          Swal.fire({
            title: response.data.data.event,
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: '#e30613'
          });
        }).catch((error) => {
          console.error('Error:', error);
          Swal.fire({
            title: 'An error occurred. Please try again.',
            icon: 'error',
          });
        });
    
      }


  return (
    <ListGroup className="zoom overflow-hidden h-100 px-0 " style={{ borderRadius: "15px" }}>
      <ListGroup.Item variant='light' className='d-flex justify-content-between align-items-center  border-0 text-center py-0 '>
        <div>
          {showEvents === 1 && <IconButton onClick={() => setShowEvents(0)}><ArrowBackIcon /></IconButton>}
          <Typography variant="small">
            {showEvents === 0 && 'My Events'}
            {showEvents === 1 && "My Event Details"}
          </Typography>

        </div>
        <IconButton className='btn btn-outline-mode' onClick={event => props.handleClick('PRFL-MENU1')}><Home /></IconButton>

      </ListGroup.Item>
      {showEvents === 0 && (
        <ListGroup.Item key={1001} className="d-flex align-items-start justify-content-center h-100  border-0 bg-light bg-opacity-75">
          <div className="container slim-scrollbar mh-70  py-0 ">

            <Row xs={1} md={1} className="g-0 " >
              <InfiniteScroll
                pageStart={0}
                loadMore={fetchData}
                hasMore={hasMore}
                initialLoad={true}
                // loader={
                //     <div className="loader" key={0}>
                //         {loadMsg}
                //     </div>
                // }
                useWindow={false}
              >
                {items.map((event, index) => (<>
                  <Col key={index} className="py-2">
                    <Card key={index} className="mb-0 shadow-sm bg-light" style={{ cursor: "pointer" }} >
                      <Card.Img variant="top" style={{ pointerEvents: 'auto' }} src={localStorage.getItem('bucketName') + '/' + event.image} onClick={() => handleEventClick(event.id)} />
                      <Card.ImgOverlay style={{ pointerEvents: 'none' }}>
                        <h4><span className="badge bg-primary">{formatDate(event.created_at)}</span></h4>
                      </Card.ImgOverlay>
                      <Card.Body className="p-2 d-flex flex-column align-items-start justify-content-between " style={{ minHeight: "75px" }}>
                        <Card.Title onClick={() => handleEventClick(event.id)} className="pt-0 my-0" style={{ lineHeight: "0.7" }}>
                          <Typography key={index} variant="large" className="d-flex align-items-center text-dark small" >
                            <BadgeAvatar isLoggedIn={false} src={localStorage.getItem('bucketName') + '/' + event.user_image} name={event.user_name} size='30' />
                            <div>
                              <div className="ms-2 mb-1"><strong>{event.user_name}</strong> </div>
                              <div className="ms-2"><medium className="text-muted">Category: {event.event_category}</medium></div>
                            </div>
                          </Typography>
                        </Card.Title>
                        <Card.Text className='d-flex align-items-center my-2 text-dark'>
                          <LocationOnOutlinedIcon /> {event.location}
                        </Card.Text>
                        {/* <Button variant="primary" size="sm">{btnText[idx]}</Button>  */}
                      </Card.Body>
                      <Card.Footer className="mx-0 px-2 py-1 d-flex justify-content-between align-items-center border-top-0">
                        <small>You and 25 more going</small>
                        {/* <Button value={index} key={index} style={{ pointerEvents: 'auto' }} onClick={() => handleInterestedClick(event.id)} variant='primary' size="sm" className="px-1 py-0 d-flex align-items-center"> */} 
                          {/* <StarIcon className="mb-1" sx={{ width: '15px', height: '15px' }} /> */}
                       {/*{isInterested ? (<><NotInterestedIcon fontSize='small'/> Interested</>) : (<><InterestedIcon  fontSize='small'/> Going</>)} </Button> */} 
                       {1?
                          (<Button value={index} key={index} style={{pointerEvents:'auto'}} onClick={(e)=>handleInterestedClick(event.id,intesetedmsg)} variant={intesetedmsgcolor} size="sm"  className="px-1 py-0"><StarIcon className="mb-1" sx={{ width: '15px', height: '15px' }} />Interested</Button>):
                          (<Button value={index} key={index} style={{pointerEvents:'auto'}} onClick={(e)=>handleInterestedClick(event.id,unintesetedmsg)} variant={unintesetedmsgcolor} size="sm" className="px-1 py-0"><StarIcon className="mb-1" sx={{ width: '15px', height: '15px' }} />Interested</Button>)                         
                          }
                      </Card.Footer>
                    </Card>
                  </Col>
                </>))}
              </InfiniteScroll>
              <div className="loader my-2 text-center" key={0} >{loadMsg}</div>


            </Row>


          </div>
        </ListGroup.Item>
      )}
      {showEvents === 1 && (<PVLNEventDetails pIndex={props.idx} rowIndex={rowIndex} handleClick={handleClick} />)}
    </ListGroup>
  )
}

export default PRFLMyEvents
