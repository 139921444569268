import React, { useEffect, useState } from 'react'
import InfiniteScroll from "react-infinite-scroller";
import ListGroup from 'react-bootstrap/ListGroup';
import { Card, Row, Col, Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
// import Button from '@mui/material/Button';
import Button from 'react-bootstrap/Button';
import Typography from '@mui/material/Typography';
import { Home } from "@mui/icons-material";
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Modal from 'react-bootstrap/Modal';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import PostAddIcon from '@mui/icons-material/PostAdd';
import Upload from "../../../Cropper/Upload";
import CropperPopup from "../../../Cropper/Popup";
import Box from "@mui/material/Box";
import 'react-bootstrap-tagsinput/dist/index.css'
import { CKEditor, ImageInsert } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import BadgeAvatar from '../../../BadgeAvatar';
import PVLNPressDetails from './PVLNPressDetails';
import axios from "axios";
import http from '../../../../http';
import pubThumbnail from '../../../../assets/img/default-pubn-thumbnail.jpg';
import { styled } from "@mui/material/styles";
import Image from 'react-bootstrap/Image';
import CRUDDropdown from '../../../CRUDDropdown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useLocation } from 'react-router-dom';
import PreLoader from '../../../PreLoader';
import { PLSIZE } from '../../../PavilionConstants';
import Swal from 'sweetalert2'
import Spinner from 'react-bootstrap/Spinner';

const CropperInput = styled("input")({
  display: "none"
});



function PVLNPress(props, { handleClick }) {
  const [permissions, setPermissions] = useState({});
  const [progress, setProgress]=useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [loadMsg, setLoadMsg] = useState(<PreLoader size={PLSIZE} />);
  const token = localStorage.getItem('token');
  const [userdetails, setUserdetails] = useState([]);
  const [post, setPost] = useState([]);
  const [category, setCategory] = useState([]);
  const libraryImagePath = localStorage.getItem('bucketName') + '/images/';
  const libraryImagePathStatic = localStorage.getItem('bucketName') + '/images/noimage.webp';
  const [bucketName, setBucketName] = useState(localStorage.getItem('bucketName'));
  const [find_event, setFilterYear] = useState('');
  const [news_article_list, setFilterName] = useState('');
  const [imagePress, setImagePress] = useState(pubThumbnail);
  const [imagePress2, setImagePress2] = useState('');
  const [imagePressedit, setImagePressEdit] = useState('');
  const [presseditor, setPresseditor] = useState('');
  const [presstime, setPresstime] = useState('');
  const [pressdate, setPressdate] = useState('');
  const [presstitle, setPresstitle] = useState('');
  const [presscategory, setPressCategory] = useState('');
  const [pressidvalue, setPressidvalue] = useState('0');
  const [image, setImage] = React.useState(pubThumbnail);


  {/*const [formData, setFormData] = useState({
    find_event: '',
    news_article_list: '',
	pavilion_id:props.idx,
});*/}

  const handlePresstime = (e) => {
    setPresstime(e.target.value);
  };
  const handlePressdate = (e) => {
    setPressdate(e.target.value);
  };
  const handlePresstitle = (e) => {
    setPresstitle(e.target.value);
  }
  const handlePressCategory = (e) => {
    setPressCategory(e.target.value);
  }

  const handlePressSumit = (e) => {
    setProgress(true)
    if (document.getElementById('pressidvalue').value != 0) {
      http().post('/createnews', {
        pavilion_id: props.idx,
        image: imagePress,
        news_purpose: "edit",
        news_id: document.getElementById('pressidvalue').value,
        category_id: presscategory,
        name: presstitle,
        start_date: pressdate,
        start_time: presstime,
        description: presseditor,
      }).then((data) => {
        Swal.fire({
          title: "It is submitted successfully!",
          icon: "success",
          allowOutsideClick: false,
          confirmButtonColor: "#e30613",
        });
        
        http().get('/news/' + props.idx).then((data) => {
          setPost(data.data.data.newsPavilionRecord.data);
          setCategory(data.data.data.libraryPavilionPublicationCategory);
        });
        setProgress(false)
        setShow(false);
      });
    } else {
      http().post('/createnews', {
        pavilion_id: props.idx,
        image: imagePress,
        news_purpose: "new",
        news_id: document.getElementById('pressidvalue').value,
        category_id: presscategory,
        name: presstitle,
        start_date: pressdate,
        start_time: presstime,
        description: presseditor,
      }).then((data) => {
        Swal.fire({
          title: "It is submitted successfully!",
          icon: "success",
          allowOutsideClick: false,
          confirmButtonColor: "#e30613",
        });
        
        http().get('/news/' + props.idx).then((data) => {
          setPost(data.data.data.newsPavilionRecord.data);
          setCategory(data.data.data.libraryPavilionPublicationCategory);
        });
        setProgress(false)
        setShow(false);
      });
    }
  }

  const handleClickEdit = (id) => {
    setShow(true)
    setPressidvalue(id);
    http().get('/editnews/' + id + '/' + props.idx).then((data) => {
      console.log(data);
      console.log(data.data);
      console.log('ssvv');
      setImagePress(data.data.newsImage);
      setPresstitle(data.data.news.name);
      setPresseditor(data.data.news.description);
      setPressCategory(data.data.news.category_id);
      setPresstime(data.data.news.newstime);
      setPressdate(data.data.dateValue);
      setImagePressEdit('');

    });
  }

  /*useEffect(() => {
    http().get('/news/'+props.idx).then((data) => {
      setPost(data.data.data.newsPavilionRecord.data);
      setCategory(data.data.data.libraryPavilionPublicationCategory);
    });
  }, []);*/

  useEffect(() => {
    http().get('/userdetails').then((data) => {
      setUserdetails(data.data.data.user);
    });
  }, []);



  const handleNameChange = (e) => {
    setFilterYear(find_event);
    var element_news_article_list = document.getElementById('news_article_list');
    var value2 = element_news_article_list.options[element_news_article_list.selectedIndex].value;
    setFilterName(value2);
  };

  const handleYearChange = (e) => {
    var element_find_event = document.getElementById("find_event");
    var value1 = element_find_event.options[element_find_event.selectedIndex].value;
    setFilterYear(value1);
    setFilterName(news_article_list);
  };

  const handleClickNews = (e) => {
    e.preventDefault();
    if (find_event.length === 0 && news_article_list.length !== 0) {
      http().post('/newssearch', {
        find_event: 'keyword',
        news_article_list: news_article_list,
        pavilion_id: props.idx,
      }).then((data) => {
        console.log(data);
        setPost(data.data.data.newsResult.data);
        setShowPress(0);
      });
    }
    if (news_article_list.length === 0 && find_event.length !== 0) {
      http().post('/newssearch', {
        find_event: find_event,
        news_article_list: 'All',
        pavilion_id: props.idx,
      }).then((data) => {
        console.log(data);
        setPost(data.data.data.newsResult.data);
        setShowPress(0);
      });
    }
    if (news_article_list.length === 0 && find_event.length === 0) {
      http().post('/newssearch', {
        find_event: 'keyword',
        news_article_list: 'All',
        pavilion_id: props.idx,
      }).then((data) => {
        console.log(data);
        setPost(data.data.data.newsResult.data);
        setShowPress(0);
      });
    }
    if (news_article_list.length !== 0 && find_event.length !== 0) {
      http().post('/newssearch', {
        find_event: find_event,
        news_article_list: news_article_list,
        pavilion_id: props.idx,
      }).then((data) => {
        console.log('hi');
        console.log(data.data.data.newsResult.data);
        setPost(data.data.data.newsResult.data);
        setShowPress(0);
      });
    }
  };
  /*
  const [newsTitle, setNewsTitle] = useState([
    'New Year 2024',
    'Annual Day Celebrations',
    'TV Debate',
    'AI in Public Health News',
    'Data Governance Press Conference ',
    'The UK Economy',
  ]);
  const [newsDate, setNewsDate] = useState([
    'Jan 22 2023',
    'Feb 19 2023',
    'Mar 11 2023',
    'May 13 2023',
    'May 13 2023',
    'June 10 2023',
  ]);
  const [newsCat, setNewsCat] = useState([
    'Articles',
    'Press Releases',
    'Radio & Podcasts',
    'TV & Videos',
  ]);
  const [newsDesn, setPNewsDesn] = useState([
    "News Desctipn 1",
    "News Desctipn 2",
    "News Desctipn 3",
    "News Desctipn 4",
    "News Desctipn 5",
    "News Desctipn 6",
  ]);
  const [newsPhoto, setNewsPhoto] = useState([
    "https://bic3-staging.s3.eu-west-2.amazonaws.com/images/publication/67131668758278.png",
    "https://bic3-staging.s3.eu-west-2.amazonaws.com/images/publication/45491667362602.png",
    "https://bic3-staging.s3.eu-west-2.amazonaws.com/images/publication/34691667363783.png",
    "https://bic3-staging.s3.eu-west-2.amazonaws.com/images/publication/97191667362774.png",
    "https://bic3-staging.s3.eu-west-2.amazonaws.com/images/publication/32051667363893.png",
    "https://bic3-staging.s3.eu-west-2.amazonaws.com/images/publication/63711667363561.png",
  ]);
*/

  const [state, setState] = useState([]);
  const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showPress, setShowPress] = useState(0)

  const [open, setOpen] = React.useState(false);


  const handleClose = () => { setOpen(false); };

  const [fileUrl, setFileUrl] = useState('https://source.unsplash.com/random/250x335/?sig=0');

  // const [open, setOpen] = useState(false);
  const [toggler, setToggler] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const handleParentClose = () => setShow(false);

  const [showFilter, setShowFilter] = useState(false);

  // const [image, setImage] = useState(defaultSrc);
  // const [cropData, setCropData] = useState("#");
  // const cropperRef = createRef<ReactCropperElement>(null);

const location = useLocation();
const splitURL = location.pathname.toString().split("/");
    useEffect(() => {
        if (splitURL[1]==='newsinformation') {
          if(splitURL[2])   {
            setShowPress(1);
          }
         }
        // alert(`The current route is Press ${splitURL[4]}`);
    }, [location]);  


  //input file dialogue
  const onFileChange = (e) => {
    e.preventDefault();
    const dataValue = e.currentTarget.getAttribute("data-value");
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (files.length === 0) {
      return alert("Please select a file.");
    }

    const reader = new FileReader();
    reader.onload = () => {
      // getUploadedFile(reader.result);
      if (dataValue === "PRSS") { setImagePress2(reader.result); setOpen(true); }
    };

    reader.readAsDataURL(files[0]);
    // setImagePress(e.target.files[0]);
    //setImagePressEdit(e.target.files[0]);

  };




  //const getCropData = () => {
  // if (typeof cropperRef.current?.cropper !== "undefined") {
  //   setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
  // }
  //};
  const [pressId, setPressId] = useState();

  const handlePressClick = (idx) => {
    //check & work
    //setShowPress(1)
    //setPressId(idx);
  }
  function handleDropdown(event) {
    event.stopPropagation();
    // console.log('handleChildClick');
  }
  const handleClickDelete = (id) => {
    Swal.fire({
      title: "Are you sure to delete this News?",
      text: "You won't be able to revert this!",
      icon: "warning",
      width: 'auto',
      showCancelButton: true,
      confirmButtonColor: "#e30613",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        http().post('/delete_resource', {
          id: id,
          type: "news",
          created_by: localStorage.getItem('localstorage_user_id'),
        }).then((data) => {
          Swal.fire({
            title: "Is is deleted successfully",
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });

          http().get('/news/' + props.idx).then((data) => {
            setPost(data.data.data.newsPavilionRecord.data);
            setCategory(data.data.data.libraryPavilionPublicationCategory);
          });


        });
      }
    });

    // 	http().post('/delete_resource', {
    // 			id:id,
    // 			type:"news",
    // 			created_by:localStorage.getItem('localstorage_user_id'),
    // 	}).then((data) => {
    //     Swal.fire({
    //       title: "It is deleted successfully!",
    //       icon: "success",
    //       allowOutsideClick: false,
    //       confirmButtonColor: "#e30613",
    //     });	
    // http().get('/news/' + props.idx ).then((data) => {
    //     setPost(data.data.data.newsPavilionRecord.data);
    //     setCategory(data.data.data.libraryPavilionPublicationCategory);
    // 	});
    // 			//console.log(data);
    // 	});
  }



  //Infinite Scroll
  const fetchData = async (__page) => {
    console.log(__page);
    const response = await http().get(
      `/news/${props.idx}?page=${page}`).then((response) => {
        //setHasMore(true)
        console.log(response.data.data.newsPavilionRecord.data)
        setPost([...post, ...response.data.data.newsPavilionRecord.data]);
        const last_page = response.data.data.newsPavilionRecord.last_page;
        setPermissions(response.data.data.permissions);
        if (page < last_page) {
          setPage(page + 1);
          setHasMore(true)
          setLoadMsg(<PreLoader size={PLSIZE} />)
        } else {
          setHasMore(false)
          setLoadMsg('No More Records...')
        }
      });
  };

  return (
    <>
      <ListGroup className="zoom overflow-hidden h-100 px-0 " style={{ borderRadius: "15px" }}>
        <ListGroup.Item variant="light" className='d-flex justify-content-between align-items-center  border-0 text-center py-0 '>
          <div>
            {showPress === 0 && (<IconButton className='btn btn-outline-mode' onClick={event => props.handleClick('PVLN-MENU', props.idx)}><ArrowBackIcon /></IconButton>)}
            {showPress > 0 && (<IconButton className='btn btn-outline-mode' onClick={() => setShowPress(0)}><ArrowBackIcon /></IconButton>)}
            <Typography variant="small">
              {showPress === 0 && "Press"}
              {showPress === 1 && "Press Details"}
              {showPress === 2 && "Search Press"}
            </Typography>
          </div>
          <IconButton className='btn btn-outline-mode' onClick={event => props.handleClick('PVLN-HOME', props.idx)}><Home /></IconButton>
          <div>
          {permissions['create-news-media'] && <IconButton className='btn btn-outline-mode' onClick={handleShow}><PostAddIcon /></IconButton>}
            <IconButton className='btn btn-outline-mode' onClick={() => setShowPress(2)}><FilterAltIcon /></IconButton>
          </div>
        </ListGroup.Item>
        {showPress === 0 && (
          <ListGroup.Item className="d-flex align-items-start justify-content-center h-100  border-0 bg-light bg-opacity-75">
            <div className="container slim-scrollbar mh-70  py-0 ">
              <InfiniteScroll
                pageStart={0}
                loadMore={fetchData}
                hasMore={hasMore}
                // initialLoad={true}
                // loader={
                //     <div className="loader" key={0}>
                //         {loadMsg}
                //     </div>
                // }
                useWindow={false}
              >
                <Row xs={1} md={1} className="g-0 mx-5" >
                  {post.map((innerArray, outerIndex) => (
                    <Col key={innerArray.id} className="py-2">
                      <Card className="mb-0 p-2 shadow-sm bg-light" style={{ cursor: "pointer" }} onClick={(event) => handlePressClick(innerArray.id)}>
                        <Card.Title className="d-flex align-items-center justify-content-between mb-2">
                          {innerArray.user.image ? (<BadgeAvatar isLoggedIn={false}
                            src={bucketName + '/images/profile_picture/' + innerArray.user.details.image} name="Stephen Metcalfe" size='40' />) : (<BadgeAvatar isLoggedIn={false} name={innerArray.user.name.charAt(0)} size='40' />)}
                          <h6 className="ms-2">{innerArray.user.name}</h6>
                          <CRUDDropdown title={<MoreVertIcon />} handleDropdown={handleDropdown} editTitle="Edit" editClick={(event) => handleClickEdit(innerArray.id)} deleteTitle="Delete" deleteClick={(event) => handleClickDelete(innerArray.id)} />
                        </Card.Title>
                        <Card.Img variant="top"
                          src={bucketName + '/images/news/' + innerArray.image} />
                        <Card.Body className="py-1 px-0 d-flex flex-column align-items-start justify-content-between " style={{ minHeight: "75px" }}>
                          <Card.Text className='d-flex align-items-center my-0 text-dark'>
                            <h5 className="badge bg-primary small">{innerArray.start_date}</h5>
                          </Card.Text>
                          <Card.Title className="pt-0 my-0" style={{ lineHeight: "0.7" }}>
                            <Typography key={innerArray.id} variant="large" className="d-flex align-items-center text-dark small" >
                              <div className=""><strong>{innerArray.name}</strong> <br></br>
                                <small className="text-muted">Category: {innerArray.category.name}</small>
                              </div>
                            </Typography>
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </InfiniteScroll>
              <div className="loader my-2 text-center" key={0} >{loadMsg}</div>
            </div>
          </ListGroup.Item>
        )}
        {showPress === 1 && (<PVLNPressDetails pressid={pressId} handleClick={handleClick} />)}
        {showPress === 2 && (
          <ListGroup.Item className=" mx-0 px-0 d-flex align-items-start justify-content-center h-100 border-0 bg-light bg-opacity-75">
            <Container>
              <Form className="mx-0 px-0" name="newsFilter" id="newsFilter" onSubmit={handleClickNews}>
                <Form type="hidden" name="pavilion_id" value={props.idx} id="pavilion_id" value={props.idx} />

                <Form.Select required size="sm" id="find_event" value={find_event} name="find_event" className="my-1 py-1" onChange={handleYearChange}>
                  <option value="keyword" selected >Search by Year</option>
                  <option value="2024" >2024</option>
                  <option value="2023" >2023</option>
                  <option value="2022" >2022</option>
                  <option value="2021" >2021</option>
                  <option value="2020" >2020</option>
                  <option value="2019" >2019</option>
                  <option value="2018" >2018</option>
                  <option value="2017" >2017</option>
                </Form.Select>
                <Form.Select name="news_article_list" id="news_article_list" value={news_article_list} size="sm" className="my-1 py-1" onChange={handleNameChange}>
                  <option selected >All</option>
                  <option value="1" >Articles</option>
                  <option value="2" >Press Releases</option>
                  <option value="3" >Radio & Podcasts</option>
                  <option value="4" >TV & Videos</option>
                  <option value="5" >Social Media</option>
                </Form.Select>
                {/* <Form.Control size="sm" name="searchTitle" type="text" placeholder="Search Topic" className="my-1 py-1"/> */}
                <Button size="sm" type="submit" >Search</Button>
              </Form>
            </Container>
          </ListGroup.Item>
        )}
      </ListGroup>
      <Modal show={show} size="lg" onHide={handleParentClose} centered>
        <Modal.Header closeButton className="py-1">
          <Modal.Title className="lead" >Add New Library</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={4} className="d-flex flex-column justify-content-center">
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer" }}>
                <Box my={2} >
                  <label htmlFor="fileBanner1">
                    <CropperInput accept="image/*" data-value="PRSS" id="fileBanner1" type="file" onChange={onFileChange} />
                    <Image style={{ borderRadius: "10px", cursor: "pointer", width: "100%", height: "100%" }} src={imagePress} />
                  </label>
                </Box>
                <CropperPopup
                  open={open} image={imagePress2} handleClose={handleClose}
                  getCroppedFile={(image) => {
                    setImagePress(image)
                    handleClose();
                  }}
                  aspectRatio={9 / 13}
                />
              </Box>
            </Col>
            <Col md={8}>
              <input type="text" style={{ display: 'none' }} name="pressidvalue" id="pressidvalue" value={pressidvalue} />
              <Form.Select required size="sm" id="category" name="category" className="my-2  py-1" onChange={handlePressCategory} >
                <option value="">Category</option>
                <option selected={presscategory == "1"} value="1">Articles</option>
                <option selected={presscategory == "2"} value="2">Press Releases</option>
                <option selected={presscategory == "3"} value="3">Radio & Podcasts</option>
                <option selected={presscategory == "4"} value="4">TV & Videos</option>
              </Form.Select>
              <Form.Control type="text" size="sm" name="newsTitle" placeholder="News Title" value={presstitle} className="my-2  py-1" onChange={handlePresstitle} />
              <Form.Group className='d-flex' >
                <Form.Control onFocus={(e) => { e.target.type = 'date' }} onBlur={(e) => { e.target.type = 'text' }} onChange={handlePressdate} type="text" size="sm" name="start-date" placeholder="Start Date" value={pressdate} className="mt-0 mb-2 me-2 w-50 py-1" />
                <Form.Control onFocus={(e) => { e.target.type = 'time' }} onBlur={(e) => { e.target.type = 'text' }} onChange={handlePresstime} type="text" size="sm" name="start-time" placeholder="Start TIme" value={presstime} className="mt-0 mb-2 w-50  py-1" />
              </Form.Group>
              <CKEditor
                editor={ClassicEditor}
                data={presseditor}
                onReady={editor => {
                  // You can store the "editor" and use when it is needed.
                  console.log('Compose News Description', editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setPresseditor(data);
                  console.log({ event, editor, data });
                }}
                onBlur={(event, editor) => {
                  console.log('Blur.', editor);
                }}
                onFocus={(event, editor) => {
                  console.log('Focus.', editor);
                }}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={handleParentClose}>
            Cancel
          </Button>
          <Button size="sm" variant="primary" onClick={handlePressSumit}>
          {progress && ( <Spinner size='sm' className="me-2" animation="border" /> )} {pressidvalue == 0 ? ('ADD') : ('UPDATE')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default PVLNPress
