import React, { useState, useEffect, Fragment,Component } from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import { Form, Row, Col, Container } from 'react-bootstrap';
import { AccountBalanceOutlined } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete';
// import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SvgIcon } from '@mui/material';
import { Home, Search } from "@mui/icons-material";
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import axios from "axios";
import http from '../../../../http';
import CRUDDropdown from '../../../CRUDDropdown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Collapse from 'react-bootstrap/Collapse';
import Spinner from 'react-bootstrap/Spinner';
import { InputTags } from 'react-bootstrap-tagsinput'
import 'react-bootstrap-tagsinput/dist/index.css'

import { TagsInput } from "react-tag-input-component";


import ToggleButton from 'react-bootstrap/ToggleButton';
import Box from '@mui/material/Box';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import { ECOSYSTEM_OPTIONS } from '../../../PavilionConstants';
import Select from 'react-select';
import PopupPM from "../../../Cropper/Popup";
import PopupFM from "../../../Cropper/Popup";
import eventBanner from '../../../../assets/img/default-event-banner.jpg';
import { styled } from "@mui/material/styles";
import { Modal,Image } from 'react-bootstrap';

import { CKEditor, ImageInsert } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import YoutubeEmbed from '../../../YoutubeEmbed';
import { useSearchParams } from "react-router-dom";
import Comments from '../../../Comments';
import Swal from 'sweetalert2'

const Input = styled("input")({
  display: "none"
});


function PVLNAbout(props, { handleClick }) {


  const theme = localStorage.getItem("data-bs-theme");

  const [progress, setProgress]=useState(false);
  const [selected, setSelected] = useState([]);
  
  const [about, setAbout] = useState('');
  const [pavilionName, setPavilionName] = useState([]);
  const [pavilionPurposeImage, setPavilionPurposeImage] = useState('');
  const [pavilionFurtherDetails, setPavilionFurtherDetails] = useState('');
  const [pavilionFurtherImg, setPavilionFurtherImg] = useState('');
  const [pavilionDescription, setPavilionDescription] = useState('');
  const [pavilionAboutKeywordsArray, setPavilionAboutKeywordsArray] = useState([]);

  const [show, setShow] = useState(false);
  const handleParentClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [foundingYear, setFoundingYear]=useState();
  const [editFoundingYear, setEditFoundingYear]=useState();
  const [location, setLocation]=useState('');
  const [editLocation, setEditLocation]=useState('');
  const [tagsPrincipalHost, setTagsPrincipalHost] = useState([]);
  const [tagsSecretriate, setTagsSecretriate] = useState([]);
  const [tagsTypeOfMember, setTagsTypeOfMember] = useState([]);
  const [tagsKeywords, setTagsKeywords] = useState([]);
  const [purposeDesn, setPurposeDesn] = useState();
  const [editPurposeDesn, setEditPurposeDesn] = useState();
  const [furtherDesn, setFurtherDesn] = useState();
  const [editFurtherDesn, setEditFurtherDesn] = useState();
  const [tagsContactUs, setTagsContactUs] = useState([]);
  const [purposeImage, setPurposeImage] = useState(eventBanner);
  const [purposeImage2, setPurposeImage2] = useState();
  const [furtherImage, setFurtherImage] = useState(eventBanner);
  const [furtherImage2, setFurtherImage2] = useState();

  const [purposeYoutubeUrl, setPurposeYoutubeUrl] = useState();
  const [furtherYoutubeUrl, setFurtherYoutubeUrl] = useState();
  const [purposeType, setPurposeType] = useState();
  const [furtherType, setFurtherType] = useState();
  
  

  const handleClose = () => { 
      setOpenPM(false); 
      setOpenFM(false); 
  };
  const [openPM, setOpenPM] = useState(false);
  const [openFM, setOpenFM] = useState(false);
  

  const [rowIndex, setRowIndex] = useState();
  const [isFree, setIsFree] = useState(false);
  const [isGuests, setIsGuests] = useState(false)
  const [etype, setEType] = useState(null);
  const handleEventTypeClick = (e) => {
    setEType(e.target.value)
  }
    
  useEffect(() => {
    http().get('/about/' + props.idx).then((data) => {
      console.log(data);
      console.log('ssss');
      setAbout(data.data.data.aboutPavilion.data);
      setPavilionName(data.data.data.aboutPavilion.name);
      setPurposeImage(data.data.data.aboutPavilion.purpose_img);
      setPurposeDesn(data.data.data.aboutPavilion.description);
    
      setFurtherImage(data.data.data.aboutPavilion.further_img);
      setFurtherDesn(data.data.data.aboutPavilion.further_details);
      setPavilionAboutKeywordsArray(data.data.data.keywordsArray);
      console.log('v');
      console.log(data.data.data.keywordsArray);
    });
  }, []);

  // const handleEdit = () => {
  //   setShow(true)
  // }

  const [selectedPM, setSelectedPM] = useState();
  const [selectedFM, setSelectedFM] = useState();
  

  const changePurposeMediaHandler = (event) => {
	if(event.target.value=='purposeYoutube'){
    setPurposeType('purposeYoutube');
    setSelectedPM(event.target.value);
	}
	if(event.target.value=='purposeImage'){
    setPurposeType('');
    setSelectedPM(event.target.value);
	}
  };
  const changeFurtherMediaHandler = (event) => {
	if(event.target.value=='furtherYoutube'){
    setFurtherType('furtherYoutube');
    setSelectedFM(event.target.value);
	}
	if(event.target.value=='furtherImage'){
    setFurtherType('');
    setSelectedFM(event.target.value);
	}	
  };
  
  const changePurposeYoutubeUrlHandler = (event) => {
    setPurposeYoutubeUrl(event.target.value.split('v=')[1]); 
  };
  const changeFurtherYoutubeUrlHandler = (event) => {
    setFurtherYoutubeUrl(event.target.value.split('v=')[1]); 
  };

//input file dialogue
const onFileChange = (e) => {
  e.preventDefault();
  let files;
  if (e.dataTransfer) {
    files = e.dataTransfer.files;
  } else if (e.target) {
    files = e.target.files;
  }
  if (files.length === 0) {
    return alert("Please select a file.");
  }
  const reader = new FileReader();
  const dataValue = e.currentTarget.getAttribute("data-value");
  reader.onload = () => {
    //    getUploadedFile(reader.result);
    if(dataValue === "purposeImage"){setPurposeImage2(reader.result); setOpenPM(true);}
    if(dataValue === "furtherImage"){setFurtherImage2(reader.result); setOpenFM(true);}
    
    
  };
  reader.readAsDataURL(files[0]);
};

//input file dialog
// const defaultSrc =
//     "https://res.cloudinary.com/olanetsoft/image/upload/v1648679302/uploadedFiles/family.jpg";

// const [imageFile, setImageFile]=useState(defaultSrc);
// const [imageFile2, setImageFile2]=useState(defaultSrc);
// const cropImage = (imageFile) => {
//   setImageFile(imageFile);
// };
// const cropImage2 = (imageFile2) => {
//   setImageFile2(imageFile2);
// };

const handleEdit = () => {
  setShow(true)
  http().get('/webabout/' + props.idx).then((data) => {
    console.log(data);
    console.log('ssss');
    console.log(data.data.data);
    console.log('ssss');
    console.log(data.data.data.principalArray);
    console.log('ssss');
    setAbout(data.data.data.aboutPavilion);
    setPavilionName(data.data.data.aboutPavilion.name);
    setEditFoundingYear(data.data.data.aboutPavilion.founded_year);
    setEditLocation(data.data.data.aboutPavilion.location);
    // alert(data.data.data.principalArray[0].value)
    setTagsPrincipalHost(data.data.data.principalArray);
    setTagsSecretriate(data.data.data.secretriatArray);
    setTagsTypeOfMember(data.data.data.typeOfMembersArray);
    setTagsKeywords(data.data.data.keywordsArray);
    setTagsContactUs(data.data.data.contactusArray);
    setPurposeImage(data.data.data.aboutPavilion.purpose_img);
    setEditPurposeDesn(data.data.data.aboutPavilion.description);
    
    setFurtherImage(data.data.data.aboutPavilion.further_img);
    setEditFurtherDesn(data.data.data.aboutPavilion.further_details);
    // setPurposeImageVideoDisplay(data.data.data.purposeImgvideoDisplay);
    // setPavilionFurtherImageVideoDisplay(data.data.data.furtherImgVideoDisplay);
    //setPavilionAboutKeywordsArrayEdit(data.data.data.keywordsArray);
    console.log('v');
    console.log(data.data.data.keywordsArray);
    if (data.data.data.aboutPavilion.further_img) {
      setSelectedFM("furtherImage");
    } else {
      setSelectedFM("furtherYoutube");
    }
    if (data.data.data.aboutPavilion.purpose_img) {
      setSelectedPM("purposeImage");
    } else {
      setSelectedPM("purposeYoutube");
    }

  });
}
        
		
const handleUpdate = () => {
  setProgress(true)
  http().post('/webupdateaboutpavilion/' + props.idx, {
    //purposeImg:pavilionPurposeImage,
    //furtherImg:pavilionFurtherImg,
    foundedYear: editFoundingYear,
    location: editLocation,
    "principalHost": tagsPrincipalHost,
    "Secretariat": tagsSecretriate,
    "typeOfMembers": tagsTypeOfMember,
    "keywords": tagsKeywords,
    "contactUs": tagsContactUs,
    purpose_file: purposeYoutubeUrl,
    purposeImg: purposeImage2,
    url: purposeYoutubeUrl,
    description: editPurposeDesn,
    urlFurther: furtherYoutubeUrl,
    furtherDescription: editFurtherDesn,
    //furtherImg:pavilionFurtherDetailsEdit,
    furtherImg: furtherImage2,
    pavilion_id: props.idx,
    purposeType: purposeType,
    furtherType: furtherType,
	
  }).then((data) => {
    Swal.fire({
      title: "Successfully updated your changes!",
      icon: "success",
      allowOutsideClick: false,
      confirmButtonColor: "#e30613",
    });
    
    setProgress(false);
    setShow(false);
    //console.log(data.documentCategory);
    //console.log(data.data.data.publication);
    //setPost(data.data.data.libraryPavilionRecord.data);
    //setCategory(data.data.data.libraryPavilionPublicationCategory);
  });
  
};


return (<>
    <ListGroup className="zoom overflow-hidden h-100 px-0 " style={{ borderRadius: "15px" }}>
      <ListGroup.Item className='d-flex justify-content-between align-items-center list-group-item-light  border-0 text-center py-0 '>
        <div>
          <IconButton className='p-1 m-1 btn btn-outline-mode' onClick={event => props.handleClick('PVLN-MENU', props.idx)}><ArrowBackIcon /></IconButton>
          <Typography variant="small">About</Typography>

        </div>
        <IconButton className='p-1 m-1 btn btn-outline-mode' onClick={event => props.handleClick('PVLN-HOME', props.idx)}><Home /></IconButton>
        <CRUDDropdown title={<MoreVertIcon fontSize='small'/>} editTitle="Edit About" editClick={handleEdit} />

      </ListGroup.Item>
      <ListGroup.Item className="d-flex align-items-start justify-content-center h-100  border-0 bg-light">
        <div className="container slim-scrollbar mh-70  py-0 ">
          <h4>{pavilionName}</h4>
          <h5>Purpose</h5>
          <p>{purposeImage ? (<img src={localStorage.getItem('bucketName') + '/images/paviion_purpose/thumbnails/' + purposeImage} width="100%" height="100%" />) : ('')}</p>
          <p dangerouslySetInnerHTML={{ __html: purposeDesn ? purposeDesn : ''}}></p>
          {/* <p>{pavilionDescription ? pavilionDescription : ''}</p> */}
          <h5>Further Details</h5>
          <p>{furtherImage ? (<img src={localStorage.getItem('bucketName') + '/images/paviion_further/thumbnails/' + furtherImage} width="100%" height="100%" />) : ('')}</p>
          <p dangerouslySetInnerHTML={{ __html: furtherDesn ? furtherDesn : ''}}></p>
          {/* <p>{pavilionFurtherDetails ? pavilionFurtherDetails : ''}</p> */}
          <h5>Keywords</h5>
          <p>{pavilionAboutKeywordsArray.map((innerArray, outerIndex) => (
            <span>#{innerArray.value} </span>
          ))}</p>

        </div>
      </ListGroup.Item>
    </ListGroup>
    <Modal show={show} size="lg" backdrop="static" keyboard={false} data-bs-theme={theme} onHide={handleParentClose} centered>
      <Modal.Header closeButton className="py-1">
        <Modal.Title className="lead" >Edit About</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={6}>
            <Form.Control type="text" name="foundingYear" placeholder="Founding Year" value={editFoundingYear}
        onChange={(e) => setEditFoundingYear(e.target.value)} className="mb-2  py-1" />
        </Col>
          <Col md={6}>   
		  <Form.Control type="text" onChange={(e) => setEditLocation(e.target.value)} value={editLocation} name="location" placeholder="Location" className="mb-2  py-1" />
            </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group className='mb-2'>
            <TagsInput  value={tagsPrincipalHost} onChange={setTagsPrincipalHost}  name="principalHost" placeHolder="Principal Host"/>
              {/* <InputTags id="principalHost" placeholder='Principal Host' size="small" className='p-0 input-sm' values={tagsPrincipalHost}  onTags={(value) => setTagsPrincipalHost(value.values)} /> */}
            </Form.Group >
          </Col>

          <Col md={6}><Form.Group className='mb-2'>
          <TagsInput  value={tagsSecretriate} onChange={setTagsSecretriate}  name="secretriate" placeHolder="Secretriate"/>
            {/* <InputTags id="secretriate" placeholder='Secretriate' size="small" className=' p-0 input-sm' values={tagsSecretriate} onTags={(value) => setTagsSecretriate(value.values)} /> */}
          </Form.Group></Col>

        </Row>
        <Row>
          <Col md={6}><Form.Group className='mb-2'>
          <TagsInput  value={tagsTypeOfMember} onChange={setTagsTypeOfMember}  name="typeOfMember" placeHolder="Type of Member"/>
            {/* <InputTags id="typeOfMember" placeholder='Type of Member' size="small" className='p-0 input-sm' values={tagsTypeOfMember} onTags={(value) => setTagsTypeOfMember(value.values)} /> */}
          </Form.Group></Col>
          <Col md={6}><Form.Group className='mb-2'>
          <TagsInput  value={tagsKeywords} onChange={setTagsKeywords}  name="keywords" placeHolder="Keywords"/>  
            {/* <InputTags id="keywords" placeholder='Keywords' size="small" className=' p-0 input-sm' values={tagsKeywords} onTags={(value) => setTagsKeywords(value.values)} /> */}
          </Form.Group></Col>
        </Row>

        <Row>
          <Col md={6}><Form.Group className='mb-2'>
          <TagsInput  value={tagsContactUs} onChange={setTagsContactUs}  name="tagsContactUs" placeHolder="Type of Contact Us"/>
            {/* <InputTags id="typeOfMember" placeholder='Type of Member' size="small" className='p-0 input-sm' values={tagsTypeOfMember} onTags={(value) => setTagsTypeOfMember(value.values)} /> */}
          </Form.Group></Col>
          <Col md={6}></Col>
        </Row>		
        <Row>
          <Col md={6}>
            <Form.Label>Purpose Description</Form.Label>
            <CKEditor
              editor={ClassicEditor}
              data={editPurposeDesn}
              onReady={editor => {
                // You can store the "editor" and use when it is needed.
                console.log('Purpose Description', editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
				setEditPurposeDesn(data);
                console.log({ event, editor, data });
              }}
              onBlur={(event, editor) => {
                console.log('Blur.', editor);
              }}
              onFocus={(event, editor) => {
                console.log('Focus.', editor);
              }}
            />
          </Col>
          <Col md={6}>	  
		  <Form.Label>Further Description</Form.Label>
            <CKEditor
              editor={ClassicEditor}
              data={editFurtherDesn}
              onReady={editor => {
                // You can store the "editor" and use when it is needed.
                console.log('Further Description', editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
				setEditFurtherDesn(data);
                console.log({ event, editor, data });
              }}
              onBlur={(event, editor) => {
                console.log('Blur.', editor);
              }}
              onFocus={(event, editor) => {
                console.log('Focus.', editor);
              }}
            />
          </Col>
        </Row>
        <Row className='mt-2'>
          <Col md={6} className='d-flex justify-content-between'>
            <Form.Select required size="sm" value={selectedPM==='purposeImage' ? 'purposeImage' : 'purposeYoutube'} onChange={changePurposeMediaHandler} id="purposeMedia" name="purposeMedia" className="my-1  py-1">
              <option value="0" disabled selected>Select Purpose Media Type</option>
              <option value="purposeImage" >Purpose Image</option>
              <option value="purposeYoutube">Purpose Youtube Url</option>
            </Form.Select>
          </Col>
          <Col md={6}>
          <Form.Select required size="sm" value={selectedFM==='furtherImage' ? 'furtherImage' : 'furtherYoutube'}  onChange={changeFurtherMediaHandler} id="furtherMedia" name="furtherMedia" className="my-1  py-1">
              <option value="0" disabled selected>Select Further Media Type</option>
              <option value="furtherImage" >Further Image</option>
              <option value="furtherYoutube">Further Youtube Url</option>
            </Form.Select>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            {selectedPM==='purposeImage' && (
              <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer"}}>
                <Box my={2}>
                  <label htmlFor="filePurposeImage">
                    <Input accept="image/*" id="filePurposeImage" data-value="purposeImage" type="file" onChange={onFileChange}/>
                    <img className='rounded-2' style={{ cursor: "pointer" }} src={purposeImage2 ? purposeImage2 : (localStorage.getItem('bucketName') +'/images/paviion_purpose/thumbnails/' + purposeImage) } alt="cropped" height="100%" width="100%" />
                  </label>
                </Box>
                <PopupPM open={openPM} handleClose={handleClose} image={purposeImage2} getCroppedFile={(image) => {
                    setPurposeImage2(image); handleClose(); }} aspectRatio={16 / 9} />
              </Box>
            )}
            {selectedPM==='purposeYoutube' && (
            <>
            <Form.Control onChange={changePurposeYoutubeUrlHandler} type="text" name="purposeYoutubeUrl" placeholder="https://www.youtube.com/watch?v=xxxxxxxxxxx" className="mb-2  py-1" />
            <YoutubeEmbed embedId={purposeYoutubeUrl} />
            </>
            )}
            
          </Col>
          <Col md={6}>
          {selectedFM==='furtherImage' && (
              <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer"}}>
                <Box my={2}>
                  <label htmlFor="fileFurtherImage">
                    <Input accept="image/*" id="fileFurtherImage" data-value="furtherImage" type="file" onChange={onFileChange}/>
                    <img className='rounded-2' style={{ cursor: "pointer" }} src={furtherImage2 ? furtherImage2 : (localStorage.getItem('bucketName') +'/images/paviion_further/thumbnails/' + furtherImage) } alt="cropped" height="100%" width="100%" />
                  </label>
                </Box>
                <PopupFM key={2} open={openFM} handleClose={handleClose} image={furtherImage2} getCroppedFile={(image) => {
                    setFurtherImage2(image); handleClose(); }} aspectRatio={16 / 9} />
              </Box>
            )}
            {selectedFM==='furtherYoutube' && (
            <>
            <Form.Control onChange={changeFurtherYoutubeUrlHandler} type="text" name="furtherYoutubeUrl" placeholder="https://www.youtube.com/watch?v=xxxxxxxxxxx" className="mb-2  py-1" />
            <YoutubeEmbed embedId={furtherYoutubeUrl} />
            </>
            )}
            
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className='d-flex align-items-center justify-content-between py-1'>
        <Row className='w-100'>
          <Col md={9}>
            &nbsp;
          </Col>
          <Col md={3} className='d-flex algin-items-center justify-content-between '>
            {/* <Button size="sm" variant="secondary" onClick={handleParentClose}> */}
            <Button size="sm" variant="secondary" onClick={handleParentClose}>
              Cancel
            </Button>
            <Button size="sm" variant="primary" onClick={handleUpdate}>
            {progress && ( <Spinner size='sm' className="me-2" animation="border" /> )} Update
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  </>
  )
}

export default PVLNAbout
