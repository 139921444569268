import React, { useState, useEffect } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { Card, Row, Col, Container, Image } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { AccountBalanceOutlined } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import { Link, useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SvgIcon } from '@mui/material';
import { Home, Search } from "@mui/icons-material";
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BadgeAvatar from '../../../../BadgeAvatar';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import Share from '@mui/icons-material/ReplyOutlined';
import ScheduleIcon from '@mui/icons-material/Schedule';
import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import DiversityIcon from '@mui/icons-material/Diversity2Outlined';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import http from '../../../../../http';

function PUBLEventDetails(props, { handleClick }) {
  const [eventDetail, setEventDetail] = useState([]);
  const [createdAt, setCreatedAt] = useState();
  const [pavilionLogo, setPavilionLogo] = useState();
  const [pavilionName, setPavilionName] = useState();
  
  // const [eventId, setEventId] = useState(props.rowIndex);
  const [eventSlugId, setEventSlugId] = useState();

  const handleImageError = (e) => {
    e.target.src = localStorage.getItem('bucketName')+'/images/noimage.webp';
  };

  const location = useLocation();
  const splitURL = location.pathname.toString().split("/");
  useEffect(() => {
      if (splitURL[1] === 'event') {
          if (splitURL[2] ) {
            setEventSlugId(splitURL[2])
          } 
      }
      
  }, []);

    useEffect(() => {
    http().get('/event/' + eventSlugId).then((res) => {
       setEventDetail([...eventDetail, res.data.data.event]);
       setPavilionLogo(res.data.data.PavilionLogo)
       setPavilionName(res.data.data.PavilionName)
       setCreatedAt(res.data.data.evnentCreatedData)
      // const startDate = new Date(res.data.data.events[0].start_date);
      // const endDate = new Date(res.data.data.events[0].end_date);
      // const createdAt = new Date(res.data.data.events[0].created_at);
      // const currentDate = new Date();
      // const millisecondsPerDay = 1000 * 60 * 60 * 24; // Number of milliseconds in a day

      // const differenceInMilliseconds = startDate - currentDate;
      // const differenceInDays = Math.ceil(differenceInMilliseconds / millisecondsPerDay);
      // setCountDays(differenceInDays);
      

      // const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true };
      // setStartDate(startDate.toLocaleString('en-US', options));
      // setEndDate(endDate.toLocaleString('en-US', options));
      // setEndDate(endDate.toLocaleString('en-US', options));
      // setCreatedAtDate(createdAt.toLocaleString('en-US', options));
    });
  }, [eventSlugId]);

  return (

    <ListGroup className="zoom overflow-hidden h-100 px-0 " style={{ borderRadius: "15px" }}>
      <ListGroup.Item variant="light" className='d-flex justify-content-between align-items-center  border-0 text-center py-0 '>
        <div>
           <IconButton className='p-1 m-1 btn btn-outline-mode' sx={{ padding: 1 }} ><Share className='flipx' /></IconButton> 
          <Typography variant="small">
              Event Shared
          </Typography>
        </div>
        {/* <IconButton className='p-1 m-1 btn btn-outline-mode' sx={{ padding: 1 }} onClick={event => props.handleClick('PVLN-HOME', props.idx)}><Home /></IconButton> */}
			{/*<IconButton sx={{ padding: 1 }} ><PostAddIcon /></IconButton>*/}

      </ListGroup.Item>
      
    <ListGroup.Item className="d-flex align-items-start justify-content-center h-100  border-0 bg-light bg-opacity-75">
      <div className="container slim-scrollbar mh-70  py-0">
      {eventDetail.map((innerArray, outerIndex) => (
        <ListGroup className="overflow-hidden h-100 px-0 " style={{ borderRadius: "10px" }}>
          <ListGroup.Item active className='d-flex justify-content-start align-items-center  border-0 text-center py-1 '>
            <Row className="w-100 g-2 d-flex justify-content-between align-items-center ">
              <Col md={2}>
                <BadgeAvatar src={pavilionLogo} name="Charles Smith" size='30' isLoggedIn={false} />
              </Col>
              <Col md={10} className=' text-start'>
                <h6 className='mb-0'>
                   {pavilionName}
                  </h6>
                <small>{createdAt}</small>
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item className='d-flex justify-content-start align-items-center  border-0 text-center py-1 '>
            <Image fluid src={localStorage.getItem('bucketName')+'/images/events/' + innerArray.image} onError={handleImageError} />
          </ListGroup.Item>
          <ListGroup.Item className='d-flex justify-content-between align-items-center  border-0 text-center py-1 '>
            <p className="d-flex-mi mb-0 text-primary">{innerArray.start_date}</p>
            <a href="javascript:void(0)" className="btn btn-link d-flex-mi mt-0 p-0 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              <span><Share className='flipx' /> Share</span></a>
          </ListGroup.Item>
          <ListGroup.Item className='d-flex justify-content-start align-items-center  border-0 text-center py-1 '>
            <h6 className="fw-bold">{innerArray.name}</h6>
          </ListGroup.Item>
          <ListGroup.Item className='d-flex justify-content-start  border-0  py-1 '>
            <div className="tab-pane fade active show" id="about" role="tabpanel">
              <ul className="list-group list-group-light mx-0">
                <li className="list-group-item d-flex justify-content-between align-items-center mb-0">
                  <div className="d-flex align-items-center">
                    <ScheduleIcon />
                    <div className="ms-3">
                      <p className="mb-0 small fw-bold"> {innerArray.start_date} - {innerArray.end_date}</p>
                      <small className="text-muted mb-0">Starts within 0 days</small>
                    </div>
                  </div>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center mb-0">
                  <div className="d-flex align-items-center">
                    <LocationIcon />
                    <div className="ms-3">
                      <p className=" mb-0 small">Location</p>
                      <small className="text-muted mb-0 fw-bold">{innerArray.location}</small>
                    </div>
                  </div>
                  <a href={`https://www.google.com/maps/place/${innerArray.location}`} target='_blank'><span className="badge rounded-pill badge-success bg-dark">Show Map</span></a>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <DiversityIcon />
                    <div className="ms-3">
                      <p className="mb-0 small">Created for {pavilionName} at Big Innovation Centre</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </ListGroup.Item>
          {/* <ListGroup.Item className='d-flex justify-content-start  border-0  py-1 '>
            <div className="container p-0">
              <div className="border-bottom p-2 sidebar-footer text-bg-primary rounded-top">
                <h5 className="mb-0 ">{interestedMembers} Interested</h5>
              </div>
              <div className="p-2 w-100" >
                <AvatarGroup>
                  {members.map((user, index) => (
                    <Avatar alt={user.name} src={localStorage.getItem('bucketName')+"/" + members.image} />
                  ))}
                </AvatarGroup>
              </div>
            </div>
          </ListGroup.Item> */}
          <ListGroup.Item className='d-flex justify-content-start  border-0  py-1 '>
            <div className="container p-0">
              <div className="container p-0">
                <div className="border-bottom p-2 sidebar-footer text-bg-primary rounded-top">
                  <h5 className="mb-0 ">Details</h5>
                </div>
                <div className='p-2' dangerouslySetInnerHTML={{ __html: innerArray.description }}/>
              </div>
            </div>
          </ListGroup.Item>
        </ListGroup>
        ))}
      </div>
    </ListGroup.Item>
    
    </ListGroup>
  );
}

export default PUBLEventDetails;
