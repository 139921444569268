import React, { useEffect, useState } from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import { Card, Row, Col, Container } from 'react-bootstrap';
import { AccountBalanceOutlined } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete';
// import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SvgIcon } from '@mui/material';
import { Home, Search } from "@mui/icons-material";
import { IconButton } from '@mui/material';
import about from '../../../../assets/icons/about.svg';
import members from '../../../../assets/icons/members.svg';
import events from '../../../../assets/icons/myevents.svg';
import polls from '../../../../assets/icons/polls.svg';
import wallPosts from '../../../../assets/icons/wallposts.svg';
import wallGallery from '../../../../assets/icons/wallgallery.svg';
import publications from '../../../../assets/icons/publications.svg';
import library from '../../../../assets/icons/myresources.svg';
import videos from '../../../../assets/icons/videos.svg';
import photos from '../../../../assets/icons/photos2.svg';
import press from '../../../../assets/icons/press.svg';
import shop from '../../../../assets/icons/shop.svg';
import axios from "axios";
import http from '../../../../http';
import { useNavigate, useLocation } from "react-router-dom";
import PreLoader from '../../../PreLoader';
import { PLSIZE } from '../../../PavilionConstants';
import BackHandIcon from '@mui/icons-material/BackHand';
import HandshakeIcon from '@mui/icons-material/Handshake';
function PVLNMenu(props, { handleClick }) {
    const [loading, setLoading] = useState(true);
    const [isWhosWho, setIsWhosWho] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false)

    // console.log(props);
    // console.log(props.idx);

    const navigate = useNavigate()


    const [pavilionAbout, setPavilionAboutlist] = useState([]);
    const [countrylist, setCountrylist] = useState([]);
    const token = localStorage.getItem('token');
    const [selectedOption, setSelectedOption] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [countryData, setcountryData] = useState('');
    const [pavilionName, setPavilionName] = useState([]);
    const [pavilionFoundYear, setPavilionFoundYear] = useState('');
    const [pavilionPrincipalHost, setPavilionPrincipalHost] = useState('');
    const [pavilionSeretriate, setPavilionSeretriate] = useState('');
    const [pavilionLocation, setPavilionLocation] = useState('');

    const location = useLocation();
    useEffect(() => {
        const splitURL = location.pathname.toString().split("/");
        if (splitURL[1] === 'poll') {
            if (splitURL[2]) { props.handleClick('PVLN-POLLS', props.idx) }
        }
        if (splitURL[1] === 'walls') {
            if (splitURL[2]) { props.handleClick('PVLN-WPOST', props.idx) }
        }
        if (splitURL[1] === 'event') {
            if (splitURL[2]) { props.handleClick('PVLN-EVNTS', props.idx) }
        }
        if (splitURL[1] === 'publication') {
            if (splitURL[2]) { props.handleClick('PVLN-WPUBS', props.idx) }
        }
        if (splitURL[1] === 'newsinformation') {
            if (splitURL[2]) { props.handleClick('PVLN-PRESS', props.idx) }
        }
        if (splitURL[1] === 'shop') {
            if (splitURL[2]) { props.handleClick('PVLN-SHOPP', props.idx) }
        }
        console.log(`The current route is Menu1 ${splitURL[1]}`);
    }, [location]);

    useEffect(() => {
        if (token) {
            if (props.idx) {
                http().get('/about/' + props.idx).then((data) => {
                    if (data.data.data.aboutPavilion.slug === `who's-who` || data.data.data.aboutPavilion.slug === `who%27s-who`) {
                        setIsWhosWho(true);
                        props.handleClick('PVLN-MMBRS', props.idx)
                    }
                    setPavilionName(data.data.data.aboutPavilion.name);
                    setPavilionFoundYear(data.data.data.aboutPavilion.founded_year);
                    setPavilionLocation(data.data.data.aboutPavilion.location);
                    setPavilionSeretriate(data.data.data.aboutPavilion.secretriat);
                    setPavilionPrincipalHost(data.data.data.aboutPavilion.principal_host);
                    setPavilionAboutlist(data.data.data.aboutPavilion.data);
                    setLoading(false);
                });
            }
        }
    }, [props.idx]);

    const pavilions = [
        {
            phost: 'UK parliament',
            seretriate: 'Big Innovation Centre',
            fyear: 2019,
            location: 'London'
        },
        {
            phost: 'UK parliament',
            seretriate: 'Big Innovation Centre',
            fyear: 2017,
            location: 'London'
        }
    ];
    return (

        <ListGroup className="overlayContainer zoom overflow-hidden h-100 px-0 " style={{ borderRadius: "15px" }}>
            {!token && (
                <div class="box stack-top" >
                    <Button onClick={() => navigate(`/`)}>Login</Button>
                </div>
            )}
            <ListGroup.Item variant="light" className='d-flex justify-content-between align-items-center  border-0 text-center py-0 '>
                <Typography variant="small">{pavilionName}</Typography>
                <IconButton onClick={event => props.handleClick('PVLN-HOME', props.idx)}><Home /></IconButton>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex flex-column align-items-center justify-content-start h-100  border-0 bg-light bg-opacity-75" >
                {loading ? (<PreLoader size={PLSIZE} />) : (<>
                <div className="container bg-light rounded py-2">
                <Row>
                <Col>
                <h6 className='mb-0 lead'>Principal Host</h6>
                <small className='text-muted'>{(pavilionPrincipalHost ? pavilionPrincipalHost.split('@##@').join(',') : '') + ','} </small>
                <h6 className='mb-0 mt-1 lead'>Founding Year</h6>
                <small className='text-muted'>{pavilionFoundYear}</small>
                </Col>
                <Col>
                <h6 className='mb-0 lead'>Secretriat</h6>
				<small className='text-muted'>  {(pavilionSeretriate ? pavilionSeretriate.split('@##@').join(',') : '') + ','}</small>
                <h6 className='mb-0 mt-1'>Location</h6>
                <small className='text-muted'>{pavilionLocation}</small>
                </Col></Row>
                </div>
                <div className="container slim-scrollbar mh-70  py-1">
                    <div className="profile-grid py-2 ">
                        
                        <Button disabled={disableBtn} style={{maxWidth:'100%'}} onClick={event => props.handleClick('PVLN-ABOUT',props.idx)} variant="light" size="sm" className="d-flex flex-column small align-items-center justify-content-end" >
                            <img src={about} className="mb-1" alt="About" />
                            <Typography variant="small">About</Typography>
                        </Button>
                        
                        <Button disabled={disableBtn}  style={{maxWidth:'100%'}} onClick={event => props.handleClick('PVLN-MMBRS',props.idx)} variant="light" size="sm" className="d-flex flex-column small align-items-center justify-content-end" >
                            <img src={members} className="mb-1" alt="Members" />
                            <Typography variant="small">Members</Typography>
                        </Button>
                        {!isWhosWho && ( 
                        <>
                        <Button disabled={disableBtn} onClick={event => props.handleClick('PVLN-EVNTS',props.idx)} variant="light" size="sm" className="d-flex small flex-column align-items-center justify-content-end" >
                            <img src={events} className="mb-1" alt="My Community" />
                            <Typography variant="small">Events</Typography>
                        </Button>
                        <Button disabled={disableBtn} onClick={event => props.handleClick('PVLN-POLLS',props.idx)} variant="light" size="sm" className="d-flex flex-column small align-items-center justify-content-end" >
                            <img src={polls} className="mb-1" alt="My Pavilion" />
                            <Typography variant="small">Polls</Typography>
                        </Button>
                        <Button disabled={disableBtn} onClick={event => props.handleClick('PVLN-WPOST',props.idx)} variant="light" size="sm" className="d-flex flex-column small align-items-center justify-content-end" >
                            <img src={wallPosts} className="mb-1" alt="Wall Posts" />
                            <Typography variant="small">Wall Posts</Typography>
                        </Button>
                        <Button disabled={disableBtn} onClick={event => props.handleClick('PVLN-WGALL',props.idx)} variant="light" size="sm" className="d-flex flex-column small align-items-center justify-content-end" >
                            <img src={wallGallery} className="mb-1" alt="My Events" />
                            <Typography variant="small">Wall Gallery</Typography>
                        </Button>
                        <Button disabled={disableBtn} onClick={event => props.handleClick('PVLN-WPUBS',props.idx)} variant="light" size="sm" className="d-flex flex-column small align-items-center justify-content-end" >
                            <img src={publications} className="mb-1" alt="My Resources" />
                            <Typography variant="small">Publications</Typography>
                        </Button>
                        <Button disabled={disableBtn} onClick={event => props.handleClick('PVLN-LIBRY',props.idx)} variant="light" size="sm" className="d-flex flex-column small align-items-center justify-content-end" >
                            <img src={library} className="mb-1" alt="My Wall" />
                            <Typography variant="small">Library </Typography>
                        </Button>
                        <Button disabled={disableBtn} onClick={event => props.handleClick('PVLN-VID0S',props.idx)} variant="light" size="sm" className="d-flex flex-column small align-items-center justify-content-end" >
                            <img src={videos} className="mb-1" alt="My Wall Gallery" />
                            <Typography variant="small">Videos</Typography>
                        </Button>
                        <Button disabled={disableBtn} onClick={event => props.handleClick('PVLN-PHOTO',props.idx)} variant="light" size="sm" className="d-flex flex-column small align-items-center justify-content-end" >
                            <img src={photos} className="mb-1" alt="Purchase History" />
                            <Typography variant="small">Photo Albums</Typography>
                        </Button>
                        <Button disabled={disableBtn} onClick={event => props.handleClick('PVLN-PRESS',props.idx)} variant="light" size="sm" className="d-flex flex-column small align-items-center justify-content-end" >
                            <img src={press} className="mb-1" alt="Help n Support" />
                            <Typography variant="small">Press</Typography>
                        </Button>
                        <Button  onClick={event => props.handleClick('PVLN-SHOPP',props.idx)} variant="light" size="sm" className="border border-1 d-flex flex-column small align-items-center justify-content-end" >
                            <img src={shop} className="mb-1" alt="Info" />
                            <Typography variant="small">Shop</Typography>
                        </Button>
                        
                        </>
                        )}
                    </div>
                    <Button  onClick={event => props.handleClick('PVLN-SUPPO',props.idx)} variant="light" size="sm" className="w-100 d-flex flex-column small align-items-center justify-content-center py-2"  >
                   <HandshakeIcon fontSize='small' sx={{ color: '#e30613' }}/> <Typography variant="small">SUPPORTERS</Typography>                      
                   </Button>
                </div>
                </> )}
            </ListGroup.Item>
        </ListGroup>
    )
}

export default PVLNMenu
