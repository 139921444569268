import React, { useState, useEffect } from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import CardGroup from 'react-bootstrap/CardGroup';
import Card from 'react-bootstrap/Card';
import { AccountBalanceOutlined } from '@mui/icons-material'
import googlePlay from '../../../../assets/icons/googleplay.svg';
import appStore from '../../../../assets/icons/appstore.svg';
import profileBanner from '../../../../assets/img/profilebanner.png';
import BadgeAvatar from '../../../BadgeAvatar';
import { Button, Row, Col, Container } from 'react-bootstrap';
import Typography from '@mui/material/Typography';
import { Reply } from "@mui/icons-material";
import { Badge, IconButton } from '@mui/material';
import XIcon from '@mui/icons-material/X';
// import Icon from '@material-ui/core/Icon';
// import { makeStyles } from '@material-ui/styles';
import Swal from 'sweetalert2';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon2 from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import myLogo from '../../../../assets/icons/biclogoicon.svg';
import Logo from '../../../Logo';
import Carousel from 'react-bootstrap/Carousel';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import EditIcon from '@mui/icons-material/DriveFileRenameOutline';
import { styled } from "@mui/material/styles";
import CropperPopup from '../../../Cropper/Popup';
import Box from "@mui/material/Box";
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import appgAILogo from '../../../../assets/img/appg-ai-logo.jpeg';
import http from '../../../../http'
import { useLocation } from 'react-router-dom'
import Spinner from 'react-bootstrap/Spinner';
const CropperInput = styled("input")({
  display: "none"
});


function MiddlePublicProfile() {
  const [isPublic, setIsPublic]=useState(false);
  const [progress, setProgress]=useState(false);
  const token = localStorage.getItem('token');
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [image, setImage] = useState(localStorage.getItem('bucketName') + '/images/profile_picture/23231686680942.png');
  const [avatar, setAvatar] = useState('')
  const [avatar2, setAvatar2] = useState(image)
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [banners, setBanners] = useState([]);
  const [imageBannerOne, setImageBannerOne] = useState();
  const [imageBannerOne2, setImageBannerOne2] = useState();
  const [imageBannerTwo, setImageBannerTwo] = useState();
  const [imageBannerTwo2, setImageBannerTwo2] = useState();
  const [imageBannerThree, setImageBannerThree] = useState();
  const [imageBannerThree2, setImageBannerThree2] = useState();
  const [userName, setUserName] = useState();
  const [userDesignation, setUserDesignation] = useState();
  const [userOrganization, setUserOrganization] = useState();
  const [userFollowers, setUserFollowers] = useState();
  const [userFollowings, setUserFollowings] = useState();
  const [userLinkedIn, setUserLinkedIn] = useState();
  const [userTwitter, setUserTwitter] = useState();
  const [userFacebook, setUserFacebook] = useState();
  const [myProfileLink, setMyprofileLink] = useState();
  const [userPavilion, setUserPavilion] = useState('https://bicpavilion.com');
  const [userBanners, setUserBanners] = useState(['defaultbanner.png']);
  const [bannerlinkcolor1, setbannerlinkcolor1] = useState(false);
  const [bannerlinkcolor2, setbannerlinkcolor2] = useState(false);
  const [bannerlinkcolor3, setbannerlinkcolor3] = useState(false);
  const bannerlinkcolor = {
    color: "#e30613"
  };
  const [deleteBanner1, setDeleteBanner1] = useState('deleteBanner1');
  const [deleteBanner2, setDeleteBanner2] = useState('deleteBanner2');
  const [deleteBanner3, setDeleteBanner3] = useState('deleteBanner3');
  const [profilePicPath, setProfilePicPath] = useState(localStorage.getItem('bucketName') + '/images/profile_picture/');
  const [coverPicPath, setCoverPicPath] = useState(localStorage.getItem('bucketName') + '/images/cover_picture/');
  const [userBannerOldOne, setUserBannerOldOne] = useState('');
  const [userBannerOldSecond, setUserBannerOldSecond] = useState('');
  const [userBannerOldThird, setUserBannerOldThird] = useState('');
  const [userImageBanner1, setUserImageBanner1] = useState('userImageBanner1');
  const [userImageBanner2, setUserImageBanner2] = useState('userImageBanner2');
  const [userImageBanner3, setUserImageBanner3] = useState('userImageBanner3');
  const [pavilionBanner1, setPavilionBanner1] = useState('pavilionBanner1');
  const [pavilionBanner2, setPavilionBanner2] = useState('pavilionBanner2');
  const [pavilionBanner3, setPavilionBanner3] = useState('pavilionBanner3');
  const [show, setShow] = useState(false);
  const handleParentClose = () => setShow(false);
  
  const location = useLocation();
  const splitURL = location.pathname.toString().split("/");
  const param = ['poll', 'walls', 'event', 'publication', 'newsinformation'];

  useEffect(() => {
    if (splitURL[1] === 'shareprofiles') {
      if (splitURL[2]) {
        http().get(`/shareprofiles/${splitURL[2]}`).then((data) => {
          setIsPublic(true);
          setAvatar(profilePicPath + data.data.data.user.image);
          setUserName(data.data.data.user.user_name);
          setUserDesignation(data.data.data.user.designation);
          setUserOrganization(data.data.data.user.organization);
          setUserFollowers(data.data.data.followers);
          setUserFollowings(data.data.data.followings);
          setUserLinkedIn(data.data.data.user.linkedin);
          setUserTwitter(data.data.data.user.twitter);
          setUserFacebook(data.data.data.user.facebook);
          setMyprofileLink(data.data.data.user.my_link);
          if (data.data.data.user&&data.data.data.user.banner&&data.data.data.user.banner.length > 0) {
            setUserBanners(data.data.data.user.banner.split(','));
          }
        });
      }
    }
  },[]);

  
  // useEffect(() => {
  //   if (imageBannerOne2) {
  //     setImageBannerOne(coverPicPath + imageBannerOne2)
  //   }
  //   else {
  //     setImageBannerOne('https://bic3-staging.s3.eu-west-2.amazonaws.com/images/bg-banner.png')
  //   }
  // }, [imageBannerOne2]);


  
  
  


  /*const handleUserProfileImage=(e)=>{
  http().post('/uploaduserpicture', {
    "profilePicture":files[0],
    }).then((data) => {
    alert('User profile image is updated');
    });
}*/


  

  const handleCopy = () => {
    const { protocol, hostname, port } = window.location;
    const base = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
    navigator.clipboard.writeText(base + '/' + myProfileLink).then(() => {
      Swal.fire({
        title: "Text copied to clipboard!",
        text: base + '/' + myProfileLink,
        icon: "success",
        allowOutsideClick: false,
        confirmButtonColor: "#e30613",
      }); 
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  };
  


  return (
    <>
      <CardGroup className="d-flex flex-column justify-content-between  h-100  " style={{ width: "320px" }}>
        <Card className="zoom mb-3 d-flex flex-column align-items-center justify-content-center py-2 bg-light bg-opacity-75" style={{ borderRadius: "15px", maxHeight: "200px" }} >
          {/* <Card.Img style={{ borderRadius: "15px", height:"175px" }} variant="top" src={appgAI} /> */}
          <Row className="w-100">
            <Col xs={2} md={2} className='d-flex flex-column align-items-center justify-content-between  gap-0'>
              <IconButton data-aos="fade-right" onClick={() => window.open(userLinkedIn, '_blank')} className="btn btn-outline-mode" size="small"><LinkedInIcon /></IconButton>
              <IconButton data-aos="fade-right" onClick={() => window.open(userTwitter, '_blank')} className="btn btn-outline-mode" size="small"><XIcon /></IconButton>
              <IconButton data-aos="fade-right" onClick={() => window.open(userFacebook, '_blank')} className="btn btn-outline-mode" size="small" ><FacebookIcon /></IconButton>
              <IconButton data-aos="fade-right" onClick={() => window.open(userPavilion, '_blank')} className="p-0" size="small" >
                <Logo src={myLogo} />
              </IconButton>

            </Col>
            <Col xs={7} md={7} className='d-flex flex-column align-items-center  gap-0'>
                <BadgeAvatar src={avatar} name={userName} size='120' isLoggedIn={isLoggedIn} />
                <Card.Title className="fs-6 fw-bold pb-0 mb-0">{userName}</Card.Title>
              <Card.Text className="small mb-0 text-center">
                {userDesignation} @ {userOrganization}
              </Card.Text>

            </Col>
            <Col xs={3} md={3} className="d-flex flex-column justify-content-between">
              <Button variant="light" data-aos="fade-left" size="sm" className="d-flex flex-column small align-items-center justify-content-end" >
                <span className="badge badge-pill badge-primary bg-primary" style={{ fontSize: "12px" }}>{userFollowings}</span>
                <Typography variant="small">Followings</Typography>
              </Button>

              <Button variant="light" size="sm" data-aos="fade-left" className="d-flex flex-column small align-items-center justify-content-end" >
                <span className="badge badge-pill badge-primary bg-primary" style={{ fontSize: "12px" }}>{userFollowers}</span>
                <Typography variant="small">Followers</Typography>
              </Button>
              <Button variant="light" size="sm" data-aos="fade-left" onClick={handleCopy} className="d-flex small flex-column align-items-center justify-content-end" >
                <Reply className="text-primary flipx" />
                <Typography variant="small" >Share</Typography>
              </Button>

            </Col>
          </Row>
        </Card>
        <Card className="zoom mb-3" style={{ borderRadius: "15px", maxHeight: '200px' }} >
          {/* <Card.Img style={{ borderRadius: "15px", height: "200px" }} variant="top" src={coverPicPath + userBanner} />  */}
          
          <Carousel className='h-100' controls={true} indicators={true}	>

            {Array.from({ length: userBanners.length }).map((_, idx) => (
              <Carousel.Item>
                {/* {userBanner?(<Card.Img style={{ borderRadius: "15px",width:"320px", height: "200px" }} variant="top" src={coverPicPath + userBanner} />) :(<Card.Img style={{ borderRadius: "15px",width:"320px", height: "200px" }} variant="top" src={localStorage.getItem('bucketName')+'/images/bg-banner.png'} />)
	  } */}
                <Card.Img style={{ borderRadius: "15px", width: "320px", height: "200px" }} variant="top" src={`${coverPicPath + ((userBanners[idx]).replace(/^.*[\\/]/, ''))}`} />
              </Carousel.Item>
            ))}
          </Carousel>
          {/* <Carousel >
          
          <Carousel.Item key={1}>
        <Image
           src={coverPicPath + userBanner} height='200px' width='320px'
          alt="Third slide"
        />
        <Carousel.Caption>
          <h5>Third slide label</h5>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
          </Carousel> */}
        </Card>
        <Card className="zoom" style={{ borderRadius: "15px", maxHeight: "80px" }}>
          <Card.Body className="d-flex flex-column align-items-center justify-content-between mb-0 pt-0 pb-2">
            {/* <Card.Title className="fs-6 fw-bold">Welcome to pCloud!</Card.Title> */}
            <Card.Text className="small my-2 text-center">
              Experience Pavilion on the go - now available on your mobile phone through the Play Store and App Store
            </Card.Text>
            <Card.Text >
              <a href="https://play.google.com/store/apps/details?id=com.pavillionapp.pavillion&hl=en&gl=US" target='_blank'>
                <img src={localStorage.getItem('bucketName') + '/images/googleplay.svg'} height="30px" className="me-2" alt="Google Play" /> </a>
              <a href="https://apps.apple.com/us/app/pavilion/id6450182778" target='_blank'>
                <img src={localStorage.getItem('bucketName') + '/images/appstore.svg'} height="30px" className="" alt="App Store" /></a>
            </Card.Text>
          </Card.Body>
        </Card>

      </CardGroup>
    </>
  )
}

export default MiddlePublicProfile
