import React, { useEffect, useState } from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import { Row, Col, Container } from 'react-bootstrap';
import { AccountBalanceOutlined } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete';
// import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Button from 'react-bootstrap/Button';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SvgIcon } from '@mui/material';
import { Home, Search, Settings } from "@mui/icons-material";
import { IconButton } from '@mui/material';
import Card from 'react-bootstrap/Card';
import CircularProgress from '@mui/material/CircularProgress';
import axios from "axios";
import http from '../../../../http';
import Spinner from 'react-bootstrap/Spinner';
import PreLoader from '../../../PreLoader';
import { PLSIZE } from '../../../PavilionConstants';
import { useLocation } from 'react-router-dom';


function PVLNHome({ handleClick }) {
  const [loading, setLoading] = useState(true);
  // const [pavilions, setPavilions] = useState(['Whos Who', 'APPG Artificial Intelligence', 'BIC Premium Members', 'The Innovators Board', 'New Pavilion', 'Best Pavilion'])
  // const [members, setMembers] = useState(['50 Members', '80 Members', '35 Members', '48 Members', '85 Members', '20 Members'])
  // const [pavilionImages,setPavilionImages]= useState([
  //     localStorage.getItem('bucketName')+"/images/pavilion/84771669355261.png",
  //     localStorage.getItem('bucketName')+"/images/pavilion/14831603467852.png",
  //     localStorage.getItem('bucketName')+"/images/pavilion/99031698518876.png",
  //     localStorage.getItem('bucketName')+"/images/pavilion/84771669355261.png",
  //     localStorage.getItem('bucketName')+"/images/pavilion/14831603467852.png",
  //     localStorage.getItem('bucketName')+"/images/pavilion/99031698518876.png",
  // ]);
  const [whosWho, setWhosWho] = useState(false);

  const [pavilionlist, setPavilionlist] = useState([]);
  const [countrylist, setCountrylist] = useState([]);
  const token = localStorage.getItem('token');
  const [selectedOption, setSelectedOption] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [countryData, setcountryData] = useState('');

  const location = useLocation();
  const splitURL = location.pathname.toString().split("/");
  useEffect(() => {

    const param = ['poll', 'walls', 'event', 'publication', 'newsinformation'];


    if (splitURL[1] === 'shop') {
      if (splitURL[2]) { handleClick('PVLN-MENU', 3) }
    }

  }, [location]);


  const handleInputChange = (event) => {
    // Update inputValue state with the value from the input field
    //setInputValue(event.target.value);
    setSelectedOption(countryData);
    setInputValue(document.getElementById('floatingSearch').value);

    http().get('/pavilions?country_id=' + countryData + '&name=' + event.target.value).then((data) => {
      setPavilionlist(data.data.data.pavilions.data);
    });


  };
  const handleSelectChange = (event) => {
    // Update the selectedOption state when the dropdown selection changes
    //setSelectedOption(event.target.value);
    setInputValue(document.getElementById('floatingSearch').value);
    if (event.target.value == 'Global') {
      setcountryData('');
      http().get('/pavilions?country_id=&name=' + inputValue).then((data) => {
        setPavilionlist(data.data.data.pavilions.data);
      });
    } else {
      setcountryData(event.target.value);
      http().get('/pavilions?country_id=' + event.target.value + '&name=' + inputValue).then((data) => {
        setPavilionlist(data.data.data.pavilions.data);
      });
    }
  };

  useEffect(() => {
    if (token) {
      http().get('/pavilions').then((data) => {
        setLoading(false);

        //  if(data.data.data.pavilions.data.slug==='who%27s-who'){
        //    setWhosWho(true);
        //  }
        //console.log(data);
        //console.log(data.data.data.pavilions);
        setPavilionlist(data.data.data.pavilions.data);
      });
      //countrylist

      http().get('/getcountries').then((data) => {
        setCountrylist(data.data.data.countries);
      });
    }
  }, []);





  return (
    <>

      <ListGroup className="zoom overflow-hidden h-100  px-0 " style={{ borderRadius: "15px" }}>
        <ListGroup.Item variant="light" key='heading' className='d-flex justify-content-between   border-0 align-items-center bg-linear-gradient-primary text-center py-0 '>
          <Typography variant="small">All Pavilions</Typography>
          <IconButton ><Settings /></IconButton>
        </ListGroup.Item>

        <ListGroup.Item key='content' className="d-flex flex-column align-items-center h-100  border-0 justify-content-start bg-light bg-opacity-75" >
          {loading ? (<PreLoader size={PLSIZE} />) : (<>
            <div className="input-group input-group-sm mb-2" >
              <input key='searchfield' type="search" className="form-control rounded input-sm w-50" id="floatingSearch" placeholder="Search Pavilions" onChange={handleInputChange} />
              <select key='searchdropdown' className="form-select form-select-md ms-2 rounded input-sm" id="inputGroupSelect04" onChange={handleSelectChange}>
                <option defaultValue="" >Global</option>
                {countrylist.map((countrylistArray, countrylistIndex) => (
                  <option value={countrylistArray.id}>{countrylistArray.name}</option>
                ))}
              </select>
            </div>

            <Row xs={1} md={2} className="g-2 slim-scrollbar" style={{ maxHeight: "65vh" }} >

              {pavilionlist.map((innerArray, outerIndex) => (
                <Col key={innerArray.id}>
                  <Card key={outerIndex} className="mb-0 shadow-sm bg-light" style={{ cursor: "pointer" }} onClick={event => handleClick(`PVLN-MENU`, innerArray.id)}>
                    <Card.Img variant="top" src={(innerArray.main_image == 'images/pavilion/') ? (localStorage.getItem('bucketName') + '/images/noimage.png') : (localStorage.getItem('bucketName') + '/' + innerArray.main_image)} />
                    <Card.Body className="p-2 d-flex flex-column justify-content-between " style={{ minHeight: "75px" }}>

                      <Card.Title className="pt-0 my-0" style={{ lineHeight: "0.7" }}>
                        <Typography key={innerArray.id} variant="small" className="lead small" >
                          {innerArray.name}
                        </Typography>
                      </Card.Title>
                      <Card.Text >
                        <Typography key={innerArray.id} variant="small" className="text-muted small">{innerArray.count}</Typography>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}

            </Row>
          </>
          )}
        </ListGroup.Item>

      </ListGroup>


    </>
  )
}

export default PVLNHome
