import React, { useEffect, useState } from 'react'
import InfiniteScroll from "react-infinite-scroller";
import ListGroup from 'react-bootstrap/ListGroup';
import { Card, Row, Col, Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import { AccountBalanceOutlined } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete';
// import Button from '@mui/material/Button';
import { Modal } from 'react-bootstrap';
import CRUDDropdown from '../../../CRUDDropdown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SvgIcon } from '@mui/material';
import { Home, Search } from "@mui/icons-material";
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BadgeAvatar from '../../../BadgeAvatar';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import PVLNPublicationDetails from './PVLNPublicationDetails';
import Box from "@mui/material/Box";
// import Upload from "../../../Cropper/Upload";
import Popup from "../../../Cropper/Popup";
import PostAddIcon from '@mui/icons-material/PostAdd';
import { InputTags } from 'react-bootstrap-tagsinput'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import pubThumbnail from '../../../../assets/img/default-pubn-thumbnail.jpg';
import axios from "axios";
import http from '../../../../http';
import { styled } from "@mui/material/styles";
import CropperPopup from "../../../Cropper/Popup";
import Image from 'react-bootstrap/Image';
import PreLoader from '../../../PreLoader';
import { PLSIZE } from '../../../PavilionConstants';
import Swal from 'sweetalert2'
import { useLocation } from 'react-router-dom';
import { TagsInput } from "react-tag-input-component";
import Spinner from 'react-bootstrap/Spinner';


const CropperInput = styled("input")({
  display: "none"
});


function PVLNPublications(props, { handleClick }) {
	
  const [permissions, setPermissions] = useState({});
  const [progress, setProgress]=useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [loadMsg, setLoadMsg] = useState(<PreLoader size={PLSIZE} />);
  const token = localStorage.getItem('token');
  const [userdetails, setUserdetails] = useState([]);
  const [post, setPost] = useState([]);
  const [category, setCategory] = useState([]);
  const libraryImagePath = localStorage.getItem('bucketName') + '/images/';
  const libraryImagePathStatic = localStorage.getItem('bucketName') + '/images/noimage.webp';
  const [imageFile, setFile] = useState('');
  const [publicationTitle, setPublicationTitle] = useState('');
  const [publicationDesn, setPublicationDesn] = useState('');
  const [publicationcategory, setPublicationcategory] = useState([]);
  const [publicationdoctype, setPublicationDocType] = useState([]);
  const [tags, setTags] = useState([]);
  const [pagesize, setPagesize] = useState('');
  const [pubdate, setPubdate] = useState('');
  const [attachFile, setAttachFile] = useState(null);
  const [previewFile, setPreviewFile] = useState(null);
  const [price, setPrice] = useState('0');
  const [checkPrice, setCheckPrice] = useState('');
  const [paymentType, setPaymentType] = useState('free');
  const [categoryData, setCategoryData] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const [publicationidvalue, setPublicationidvalue] = useState('0');
  const [state, setState] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [show, setShow] = useState(false);
  const [image, setImage] = React.useState(pubThumbnail);
  const [yearsData, setYearsData] = useState([]);
  const [category_id, setCategoryId] = useState([]);
  const [document_type, setDocumentType] = useState([]);
  const [year, setYear] = useState([]);
  const [filter, setFilter] = useState([]);
  const [imagePress, setImagePress] = useState(pubThumbnail);
  const [imagePress2, setImagePress2] = useState('');
  const [imagePressedit, setImagePressEdit] = useState('');
  const [publicationImagecheck, setpublicationImagecheck] = useState('');
  const [showPosts, setShowPosts] = useState(0);

  const location = useLocation();
  const splitURL = location.pathname.toString().split("/");
  useEffect(() => {
    if (splitURL[1] === 'publication') {
      if (splitURL[2]) {
        setShowPubs(1);
      }
    }

  }, [location]);

  const handlePublicationCategoryChange = (e) => {
    setCategoryId(e.target.value);
  };
  const handlePublicationDocumentChange = (e) => {
    setDocumentType(e.target.value);
  };
  const handlePublicationYearChange = (e) => {
    setYear(e.target.value);
  };
  const handlePublicationTitleChange = (e) => {
    setFilter(e.target.value);
  };


  const handleParentClose = () => { setProgress(false); setShow(false); }

  const [errors, setErrors] = useState({
    publicationTitle: '',
    publicationDesn: '',
    publicationcategory: '',
    publicationdoctype: '',
    tags: [],
    pagesize: '',
    pubdate: '',
    attachFile: null,
    previewFile: null,
    imagePress: '',
  });


  const handleShow = () => {
    setShow(true);
    setPublicationTitle('');
    setPublicationDesn('');
    setPublicationcategory('');
    setPublicationDocType('');
    setTags([]);
    setPagesize('');
    setPaymentType('');
    setCheckPrice('');
    setPrice('0');
    setPubdate('');
    setAttachFile(null);
    setPreviewFile(null);
    setImage(pubThumbnail);
    setPublicationidvalue('0');
    setImagePress(pubThumbnail);
  }

  const handlePublicationName = (e) => {
    setPublicationTitle(e.target.value);
  };
  const handlePublicationDescription = (e) => {
    setPublicationDesn(e.target.value);
  };
  const handlePublicationCategory = (e) => {
    setPublicationcategory(e.target.value);
  };
  const handlePublicationDoctype = (e) => {
    setPublicationDocType(e.target.value);
  };
  const handlePublicationTags = (e) => {
    setTags(e.target.value);
  };
  const handlePublicationPages = (e) => {
    setPagesize(e.target.value);
  };
  const handlePaymentTypeClick = (e) => {
    setPaymentType(e.target.value);
    if (e.target.value == "paid") {
      setCheckPrice('price');
    } else {
      setCheckPrice('');
    }
  };
  const handlePriceChange = (e) => {
    setPrice(e.target.value);
  };
  const handlePublicationDate = (e) => {
    setPubdate(e.target.value);
  };
  const handlePublicationAttachFile = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (files.length === 0) {
      return alert("Please select a file.");
    }
    const reader = new FileReader();
    reader.onload = () => {
      setAttachFile(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };
  const handlePublicationPreviewFile = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (files.length === 0) {
      return alert("Please select a file.");
    }
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewFile(reader.result);
    };
    reader.readAsDataURL(files[0]);
    // setPreviewFile(e.target.files[0]);
  };


  const handleClickPublication = (e) => {
    setShowPubs(0);
    http().post('/searchpublication', {
      pavilion_id: props.idx,
      category_id: category_id,
      document_type: document_type,
      year: year,
      filter: filter,
      type: "category",
    }).then((data) => {
      //console.log(data.documentCategory);
      //console.log(data.data.data.publication);
      setPost(data.data.data.publication.data);
      setCategory(data.data.data.documentCategory);
    });

  };


  const handleClickPublicationSubmit = (e) => {
    // alert(pubThumbnail);
    // alert(imagePress);
    const newErrors = {};
    if (imagePress === '/static/media/default-pubn-thumbnail.ebb11ef1f10383bb3556.jpg') {
      newErrors.imagePress = 'Image is required.';
    }

    if (!publicationTitle) {
      newErrors.publicationTitle = 'Publication Title is required.';
    }

    if (!publicationDesn) {
      newErrors.publicationDesn = 'Publication Description is required.';
    }
    if (!publicationcategory) {
      newErrors.publicationcategory = 'Publication Category is required.';
    }
    if (!publicationdoctype) {
      newErrors.publicationdoctype = 'Publication Document is required.';
    }
    if (!tags) {
      newErrors.tags = 'Tags is required.';
    }
    if (!pagesize) {
      newErrors.pagesize = 'Page Size is required.';
    }
    if (!pubdate) {
      newErrors.pubdate = 'Publication Date is required.';
    }
    if (document.getElementById('publicationidvalue').value == 0) {

      if (!attachFile) {
        newErrors.attachFile = 'Attach File is required.';
      }
      if (!previewFile) {
        newErrors.previewFile = 'Preview File is required.';
      }
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setProgress(true)
      setShow(false);
      if (document.getElementById('publicationidvalue').value != 0) {
        // alert(publicationImagecheck + 'vvvv' + imagePress)
        if (publicationImagecheck === imagePress) {
          http().post('/webcreatepublication', {
            pavilion_id: props.idx,
            name: publicationTitle,
            description: publicationDesn,
            category_id: publicationcategory,
            document_type: publicationdoctype,
            tags: tags,
            pages: pagesize,
            payment_type: document.getElementById('paymentType').value,
            price: price,
            publication_date: pubdate,
            publication_state: "edit",
            file: attachFile,
            preview_file: previewFile,
            image: '',
            publication_id: document.getElementById('publicationidvalue').value,
          }).then((data) => {
            Swal.fire({
              title: "It is submitted successfully!",
              icon: "success",
              allowOutsideClick: false,
              confirmButtonColor: "#e30613",
            });
            setProgress(false)
            http().get('/publication/' + props.idx).then((data) => {
              setPost(data.data.data.publication.data);
              setCategory(data.data.data.documentCategory);
            });
          });
        } else {
          http().post('/webcreatepublication', {
            pavilion_id: props.idx,
            name: publicationTitle,
            description: publicationDesn,
            category_id: publicationcategory,
            document_type: publicationdoctype,
            tags: tags,
            pages: pagesize,
            payment_type: document.getElementById('paymentType').value,
            price: price,
            publication_date: pubdate,
            publication_state: "edit",
            file: attachFile,
            preview_file: previewFile,
            image: imagePress,
            publication_id: document.getElementById('publicationidvalue').value,
          }).then((data) => {
            Swal.fire({
              title: "It is submitted successfully!",
              icon: "success",
              allowOutsideClick: false,
              confirmButtonColor: "#e30613",
            });
            setProgress(false)
            http().get('/publication/' + props.idx).then((data) => {
              setPost(data.data.data.publication.data);
              setCategory(data.data.data.documentCategory);
            });
          });
        }




      } else {
        setProgress(true)
        http().post('/webcreatepublication', {
          pavilion_id: props.idx,
          name: publicationTitle,
          description: publicationDesn,
          category_id: publicationcategory,
          document_type: publicationdoctype,
          tags: tags,
          pages: pagesize,
          payment_type: document.getElementById('paymentType').value,
          price: price,
          publication_date: pubdate,
          publication_state: "create",
          file: attachFile,
          preview_file: previewFile,
          image: imagePress
        }).then((data) => {
          setImagePress(pubThumbnail);
          Swal.fire({
            title: "It is submitted successfully!",
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });
          setProgress(false)
          http().get('/publication/' + props.idx).then((data) => {
            setPost(data.data.data.publication.data);
            setCategory(data.data.data.documentCategory);
          });
        });
      }
    }
  };



  useEffect(() => {
    http().get('/publicationDocumentTypeAndCategory/' + props.idx).then((data) => {
      console.log('ssss');
      console.log(data.data.data.publicationCategory);
      console.log(data.data.data.documentCategory);
      setCategoryData(data.data.data.publicationCategory);
      setDocumentData(data.data.data.documentCategory);
    });
  }, []);

  const handleClickEdit = (id) => {
    setShow(true)
    setPublicationidvalue(id);
    http().get('/editpublication/' + id + '/' + props.idx).then((data) => {
      console.log(data);
      console.log(data.data.data);
      console.log(data.data.data.publication);
      console.log('ssvv');
      console.log(data.data.data.publicationDate);
      console.log(data.data.publicationDate);
      setImage(data.data.data.publicationImage);
      setPublicationTitle(data.data.data.publication.name);
      setPublicationDesn(data.data.data.publication.description);
      setPublicationcategory(data.data.data.publication.publication_category_id);
      setPublicationDocType(data.data.data.publication.publications_document_type);
      setTags(data.data.data.publication.publications_tags.split('@##@'));
      setPagesize(data.data.data.publication.pages);
      setPaymentType(data.data.data.publication.payment_type);
      if (data.data.data.publication.payment_type == 'paid') {
        setCheckPrice('price');
      } else {
        setCheckPrice('');
      }
      setPrice(data.data.data.publication.price);
      setPubdate(data.data.data.publicationDate);
      setAttachFile('');
      setPreviewFile('');
      console.log('vvvv' + data.data.data.publication.publications_preview_file);
      setPublicationidvalue(data.data.data.publication.id);
      console.log('ssss' + data.data.data.publication.file);
      setImagePress(data.data.data.publicationImage);
      setpublicationImagecheck(data.data.data.publicationImage);
      setImagePressEdit('');


    });
  }
  const handleClickDelete = (id) => {
    Swal.fire({
      title: "Are you sure to delete this Publication?",
      text: "You won't be able to revert this!",
      icon: "warning",
      width: 'auto',
      showCancelButton: true,
      confirmButtonColor: "#e30613",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        http().post('/delete_resource', {
          id: id,
          type: "publication",
        }).then((data) => {
          Swal.fire({
            title: "It is deleted successfully",
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });

          http().get('/publication/' + props.idx).then((data) => {
            setPost(data.data.data.publication.data);
            setCategory(data.data.data.documentCategory);
            setPermissions(data.data.data.permission);
          });


        });
      }
    });


    // http().post('/delete_resource', {
    // 		id:id,
    // 		type:"publication",
    // }).then((data) => {
    //    alert('it is deleted successfully')
    //  	http().get('/library/' + props.idx ).then((data) => {
    //     setPost(data.data.data.libraryPavilionRecord.data);
    //     setCategory(data.data.data.libraryPavilionPublicationCategory);
    //   });
    // });
  }

  /*	
    useEffect(() => {
      http().get('/publication/'+props.idx ).then((data) => {
        console.log(data);
        setPost(data.data.data.publication.data);
        setCategory(data.data.data.documentCategory);
      });
    }, []);  
    */
  useEffect(() => {
    http().get('/userdetails').then((data) => {
      setUserdetails(data.data.data.user);
    });
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  const [dsType, setDSType] = useState(false);

  const handleDSTypeClick = (e) => {
    if (e.target.value === "0") {
      setDSType(true)
    } else {
      setDSType(false)
    }
  }



  const [showPubs, setShowPubs] = useState(0)
  const [publicationId, setPublicationId] = useState()
  const handlePublicationClick = (idx) => {
    setPublicationId(idx);
    setShowPubs(1);
  }
  //const handleShow = () => setShow(true);


  //input file dialogue
  const onFileChange = (e) => {
    e.preventDefault();
    const dataValue = e.currentTarget.getAttribute("data-value");
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (files.length === 0) {
      return alert("Please select a file.");
    }

    const reader = new FileReader();
    reader.onload = () => {
      // getUploadedFile(reader.result);
      if (dataValue === "PUBLICATIONCROP") { setImagePress2(reader.result); setOpen(true); }
    };
    reader.readAsDataURL(files[0]);
    //setImagePress(e.target.files[0]);
    //setImagePressEdit(e.target.files[0]);

  };
  //input file dialog
  function handleDropdown(event) {
    event.stopPropagation();
    // console.log('handleChildClick');
  }
  function handleEdit(event) {
    console.log('handleEdit')
  }

  //Infinite Scroll
  const fetchData = async (__page) => {
    console.log(__page);
    const response = await http().get(
      `/publication/${props.idx}?page=${page}`).then((response) => {
        setHasMore(true)
        console.log(response.data.data.publication.data)
        setPost([...post, ...response.data.data.publication.data]);
        setPermissions(response.data.data.permission);
        const last_page = response.data.data.publication.last_page;

        if (page < last_page) {
          setPage(page + 1);
          setHasMore(true)
          setLoadMsg(<PreLoader size={PLSIZE} />)
        } else {
          setHasMore(false)
          setLoadMsg('No More Records...')
        }
      });
  };

  const handleAddToResources = (publicationId) => {
    var button = document.getElementById('publicationcard_' + publicationId);
    if (button.textContent == 'Add to Resources') {
      button.textContent = 'Remove from Resources';
      http().get('/editpublication/' + publicationId + '/' + props.idx).then((data) => {
        http().post('/addtomylibrary', {
          id: publicationId,
          type: "publication",
          librarydata: "add",
          title: data.data.data.publication.name,
          image: data.data.data.publication.image,
          file: data.data.data.publication.file,
          createdby: data.data.data.publication.created_by,
          createdat: data.data.data.publication.created_at,
          updatedat: data.data.data.publication.updated_at,
          actualid: null,
        }).then((data) => {
          Swal.fire({
            title: "Add To Resources is submitted successfully!",
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });
          //alert('Add To Resources is submitted successfully');
        });
      });

    } else {
      button.textContent = 'Add to Resources';
      http().get('/editpublication/' + publicationId + '/' + props.idx).then((data) => {
        http().post('/addtomylibrary', {
          id: publicationId,
          type: "publication",
          librarydata: "remove",
          title: data.data.data.publication.name,
          image: data.data.data.publication.image,
          file: data.data.data.publication.file,
          createdby: data.data.data.publication.created_by,
          createdat: data.data.data.publication.created_at,
          updatedat: data.data.data.publication.updated_at,
          actualid: 'some',
        }).then((data) => {
          Swal.fire({
            title: "Removed From Resources is submitted successfully!",
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });
          //alert('Removed From Resources is submitted successfully');
        });
      });
    }

  }
  return (
    <>
      <ListGroup className="zoom overflow-hidden h-100 px-0 " style={{ borderRadius: "15px" }}>
        <ListGroup.Item variant="light" className='d-flex justify-content-between align-items-center  border-0 text-center py-0 '>
          <div>
            {showPubs === 0 && (<IconButton onClick={event => props.handleClick('PVLN-MENU', props.idx)}><ArrowBackIcon /></IconButton>)}
            {showPubs > 0 && (<IconButton onClick={() => setShowPubs(0)}><ArrowBackIcon /></IconButton>)}
            <Typography variant="small">
              {showPubs === 0 && "Publications"}
              {showPubs === 1 && "Publication Details"}
              {showPubs === 2 && "Search Publications"}
            </Typography>
          </div>
          <IconButton onClick={event => props.handleClick('PVLN-HOME', props.idx)}><Home /></IconButton>
          <div>
          {permissions['create-publications'] && <IconButton onClick={handleShow}><PostAddIcon /></IconButton>}
          {permissions['search-publications'] && <IconButton onClick={() => setShowPubs(2)}><FilterAltIcon /></IconButton>}
          </div>
        </ListGroup.Item>
        {showPubs === 0 && (
          <ListGroup.Item className="d-flex align-items-start justify-content-center h-100  border-0 bg-light bg-opacity-75">
            <div className="container slim-scrollbar mh-70  py-0 ">
              <InfiniteScroll
                pageStart={0}
                loadMore={fetchData}
                hasMore={hasMore}
                initialLoad={true}
                // loader={
                //     <div className="loader" key={0}>
                //         {loadMsg}
                //     </div>
                // }
                useWindow={false}
              >
                <Row xs={1} md={1} className="g-0 mx-5" >
                  {post.map((innerArray, outerIndex) => (
                    <Col key={innerArray.id} className="py-2">
                      <Card className="mb-0 px-2 pt-0 pb-2 shadow-sm bg-light" style={{ cursor: "pointer" }} >
                        <Card.Title className="d-flex align-items-center justify-content-between mb-2" onClick={(event) => handlePublicationClick(innerArray.id)}>
                          <BadgeAvatar isLoggedIn={false} src={localStorage.getItem('bucketName') + '/images/profile_picture/' + innerArray.users_image} name={innerArray.user_name} size='40' />
                          <h6 className="ms-2">{innerArray.user_name}</h6>
                          { (permissions['edit-publications'] && permissions['delete-publications']) && (
                          <CRUDDropdown title={<MoreVertIcon />} handleDropdown={handleDropdown} 
                          editTitle="Edit" editClick={(event) => handleClickEdit(innerArray.id)} 
                          deleteTitle="Delete" deleteClick={(event) => handleClickDelete(innerArray.id)} 
                           />  
                          )}
                          { (permissions['edit-publications'] && !permissions['delete-publications']) && (
                          <CRUDDropdown title={<MoreVertIcon />} handleDropdown={handleDropdown} 
                          editTitle="Edit" editClick={(event) => handleClickEdit(innerArray.id)} 
                           />  
                          )}
                          { (!permissions['edit-publications'] && permissions['delete-publications']) && (
                          <CRUDDropdown title={<MoreVertIcon />} handleDropdown={handleDropdown} 
                          deleteTitle="Delete" deleteClick={(event) => handleClickDelete(innerArray.id)} 
                           />  
                          )}
                          
                          
                        </Card.Title>
                        <Card.Img variant="top" src={localStorage.getItem('bucketName') + '/images/publication/' + innerArray.image} onClick={(event) => handlePublicationClick(innerArray.id)}/>
                        <Card.Body className="py-1 px-0 d-flex flex-column align-items-start justify-content-between " style={{ minHeight: "75px" }}>
                          <Card.Text className='d-flex align-items-center my-0 text-dark'>
                            <h5 className="badge bg-primary small">{innerArray.date}</h5>
                          </Card.Text>
                          <Card.Title className="pt-0 my-0" style={{ lineHeight: "0.7" }}>
                            <Typography variant="large" className="d-flex align-items-center text-dark small" >
                              <div className=""><strong>{innerArray.name}</strong> <br></br>
                                <small className="text-muted">
                                  {category.map((innercategoryArray, outercategoryIndex) => (
                                    <span>{innercategoryArray.id == innerArray.category_id ? (
                                      <span>Category: {innercategoryArray.name}</span>
                                    ) : (''
                                    )}</span>
                                  ))}
                                </small>
                              </div>
                            </Typography>
                          </Card.Title>
                        </Card.Body>
                        <Card.Footer className="d-flex justify-content-between align-atems-center px-0 pb-0 pt-2">
                          <Button onClick={() => handleAddToResources(innerArray.id)} variant="primary" size="sm" className="small px-1 py-0" id={'publicationcard_' + innerArray.id}>
                            {(innerArray.addtomyresource == '' || innerArray.addtomyresource == null) ? ("Add to Resources") : ("Remove from Resources")}</Button>
                          <Button variant="primary" size="sm" className="py-1 small" onClick={(event) => handlePublicationClick(innerArray.id)}>
                            <RemoveRedEyeOutlinedIcon /> 
                          </Button>
                        </Card.Footer>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </InfiniteScroll>
              <div className="loader my-2 text-center" key={0} >{loadMsg}</div>
            </div>
          </ListGroup.Item>
        )}
        {showPubs === 1 && (<PVLNPublicationDetails publicationId={publicationId} pavilionId={props.idx} type="publications" handleClick={handleClick} />)}
        {showPubs === 2 && (
          <ListGroup.Item className=" mx-0 px-0 d-flex align-items-start justify-content-center h-100 border-0 bg-light bg-opacity-75">
            <Container>
              <Form className="mx-0 px-0 publicationFilter" id="publicationFilter"  >
                <Form.Select size="sm" id="category_id" name="category_id" className="my-1 py-1" onChange={handlePublicationCategoryChange} >
                  <option value="">All Categories</option>
                  {categoryData.map((innerArray, outerIndex) => (
                    <option value={innerArray.id} >{innerArray.name}</option>
                  ))}
                </Form.Select>
                <Form.Select id="document_type" name="document_type" size="sm" className="my-1 py-1" onChange={handlePublicationDocumentChange}>
                  <option value="">All Document Types</option>
                  {documentData.map((innerArray, outerIndex) => (
                    <option value={innerArray.id} >{innerArray.name}</option>
                  ))}
                </Form.Select>
                <Form.Select id="year" name="year" size="sm" className="my-1 py-1" onChange={handlePublicationYearChange}>
                  <option value="">Search By Year</option>
                  {yearsData.map((innerArray) => (
                    <option value={innerArray} >{innerArray}</option>
                  ))}
                </Form.Select>
                <Form.Control size="sm" id="filter" name="filter" value={filter} onChange={handlePublicationTitleChange} type="text" placeholder="Search by Title" className="my-1 py-1" />
                <Button size="sm" onClick={handleClickPublication}>Search</Button>
              </Form>
            </Container>
          </ListGroup.Item>
        )}
      </ListGroup>
      <Modal show={show} size="lg" onHide={handleParentClose} centered>
        <Modal.Header closeButton className="py-1">
          <Modal.Title className="lead" >Publication </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type="text" style={{ display: 'none' }} name="publicationidvalue" id="publicationidvalue" value={publicationidvalue} />

          <Row>
            <Col md={4} className="d-flex flex-column justify-content-center">
              <div >
                {/* <MyCropper sendCropData={getCropData}/> */}
                {/*<Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                  }}
                >
                  <Box my={2} >
                    <label htmlFor="contained-button-file">
                      <Input
                        accept="image/*"
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={onFileChange}
                      />
                      <img className='rounded-2' style={{ cursor: "pointer" }} src={image} alt="cropped" height="100%" width="100%" />
                    </label>
					</Box>*/}

                {/* <Upload image={image}
                    getUploadedFile={(image) => {
                      setOpen(true);
                      setImage(image);
                    }}
                  /> */}
                {/*<Popup
                    open={open}
                    handleClose={handleClose}
                    image={image}
                    getCroppedFile={(image) => {
                      setImage(image);
                      handleClose();
                    }}
                    aspectRatio={9 / 13}
                  />
				  </Box>*/}
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer" }}>
                  <Box my={2} >
                    <label htmlFor="fileBanner1">
                      <CropperInput accept="image/*" data-value="PUBLICATIONCROP" id="fileBanner1" type="file" onChange={onFileChange} />
                      <Image style={{ borderRadius: "10px", cursor: "pointer", width: "100%", height: "100%" }} src={imagePress} />
                    </label>
                  </Box>
                  <CropperPopup
                    open={open} image={imagePress2} handleClose={handleClose}
                    getCroppedFile={(image) => {
                      setImagePress(image)
                      handleClose();
                    }}
                    aspectRatio={9 / 13}
                  />
                </Box>
              </div>
              {errors.imagePress && <div style={{ color: 'red' }}>{errors.imagePress}</div>}
              {/* <div>{output && <img src={output} />}</div> */}

              {/* <img id="libraryBanner" onClick={onImageClick} src={fileUrl} /> */}
              {/* </ReactCrop> */}
              {/* <img className="card-img-top rounded mx-0 py-2" src="https://source.unsplash.com/random/250x335/?sig=0"/> */}


            </Col>
            <Col md={8}>
              <input type="text" style={{ display: 'none' }} name="publicationidvalue" id="publicationidvalue" value={publicationidvalue} />
              <input type="text" style={{ display: 'none' }} name="publicationImagecheck" id="publicationImagecheck" value={publicationImagecheck} />
              <Form.Control type="text" size="sm" name="publicationTitle" id="publicationTitle" placeholder="Publication Title" className="my-2  py-1" onChange={handlePublicationName} value={publicationTitle} />
              {errors.publicationTitle && <div style={{ color: 'red' }}>{errors.publicationTitle}</div>}
              <Form.Control type="text" as="textarea" rows={3} size="sm" name="publicationDesn" id="publicationDesn" value={publicationDesn} placeholder="Description" className="my-2  py-1" onChange={handlePublicationDescription} />
              {errors.publicationDesn && <div style={{ color: 'red' }}>{errors.publicationDesn}</div>}
              <Form.Select required value={publicationcategory} size="sm" id="publicationcategory" onChange={handlePublicationCategory} name="publicationcategory" className="my-2  py-1">
                <option value="">Category</option>
                {categoryData.map((innerArray, outerIndex) => (
                  <option selected={publicationcategory == innerArray.id} value={innerArray.id} >{innerArray.name}</option>
                ))}
              </Form.Select>
              {errors.publicationcategory && <div style={{ color: 'red' }}>{errors.publicationcategory}</div>}
              <Form.Select required size="sm" id="publicationdoctype" onChange={handlePublicationDoctype} name="publicationdoctype" className="my-2  py-1">
                <option value="">Document Type</option>
                {documentData.map((innerArray, outerIndex) => (
                  <option selected={publicationdoctype == innerArray.id} value={innerArray.id} >{innerArray.name}</option>
                ))}
              </Form.Select>
              {errors.publicationdoctype && <div style={{ color: 'red' }}>{errors.publicationdoctype}</div>}

              <div className='input-group my-2 py-1'>
                <TagsInput value={tags} onChange={setTags} id='tags' name="tags" placeHolder="Tags" />
                {/* <Form.Control type="text" required placeholder='Tags' value={tags} size="small" className='p-0 input-sm' name="tags" id="tags" onChange={handlePublicationTags} /> */}
                {/* <button
                  className='btn btn-outline-secondary btn-sm my-0'
                  type='button'
                  data-testid='button-clearAll'
                  onClick={() => {
                    setTags([])
                  }}
                >
                  Clear All
                </button> */}
              </div>
              {errors.tags && <div style={{ color: 'red' }}>{errors.tags}</div>}
              <Form.Control type="number" size="sm" name="pagesize" value={pagesize} onChange={handlePublicationPages} placeholder="Page Size" className="my-2  py-1" />
              {errors.pagesize && <div style={{ color: 'red' }}>{errors.pagesize}</div>}
              <Form.Select required onClick={handlePaymentTypeClick} size="sm" id="paymentType" name="paymentType" className="my-2  py-1">
                <option selected={paymentType == "free"} value="free">Free</option>
                <option selected={paymentType == "paid"} value="paid">Paid</option>
              </Form.Select>
              {checkPrice === 'price' ? (<Form.Control type="integer" size="sm" id="price" name="price" placeholder="Price" className="my-2  py-1" onChange={handlePriceChange} />)
                : ('')}
              <Form.Control type="text" value={pubdate} onFocus={(e) => { e.target.type = 'date' }} onBlur={(e) => { e.target.type = 'text' }} size="sm" name="pubdate" id="pubdate" onChange={handlePublicationDate} placeholder="Publication Date" className="my-2  py-1" />
              {errors.pubdate && <div style={{ color: 'red' }}>{errors.pubdate}</div>}
              <Form.Group className="d-flex align-items-center my-0  py-0">
                <span>Attach</span>&nbsp;&nbsp;&nbsp;&nbsp;
                <Form.Control size="sm" type="file" id="attachFile" name="attachFile" accept="application/pdf" onChange={handlePublicationAttachFile} className="my-0  py-1 " />

              </Form.Group>
              {publicationidvalue == 0 && errors.attachFile && <div style={{ color: 'red' }}>{errors.attachFile}</div>}
              <Form.Group className="d-flex align-items-center my-0  py-2">
                <span>Preview</span>&nbsp;<Form.Control size="sm" placeholder="Preview File" accept="application/pdf" type="file" id="previewFile" name="previewFile" onChange={handlePublicationPreviewFile} className="my-0  py-1" />
              </Form.Group>
              {publicationidvalue == 0 && errors.previewFile && <div style={{ color: 'red' }}>{errors.previewFile}</div>}


            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-between">
          <Form.Check className="px-0 mb-0">
            <Form.Check.Label for="featuredOfferings" >
              <Form.Check.Input id="featuredOfferings" size="sm" className="me-2" name="group1" type="checkbox" />
              Marked as Featured Offerings
            </Form.Check.Label>
          </Form.Check>

          <div><Button size="sm" className="me-2" variant="secondary" onClick={handleParentClose}>
            Cancel
          </Button>
            <Button size="sm" variant="primary" onClick={handleClickPublicationSubmit}>
              {progress && (<Spinner size='sm' className="me-2" animation="border" />)} {publicationidvalue == 0 ? ('ADD') : ('UPDATE')}
            </Button></div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default PVLNPublications
